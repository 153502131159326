import { useTheme } from "@emotion/react";
import "./menu-mobile.css";
import CloseIcon from "@mui/icons-material/Close";
import LanguageModalMobile from "./LanguageModalMobile";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

function MenuMobileModal({ onClose }) {
	const theme = useTheme();
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const iconStyles = {
		color: theme.palette.customOpposite.main,
		stroke: "white",
		strokeWidth: 1,
	};
	return (
		<div
			className="menu-modal-container"
			style={{
				top: "85px",
				right: "0",
				width: "100%",
				height: "90vh",
				backgroundColor: theme.palette.primary.main1,
				border: `1px solid ${theme.palette.customOppositeLighter.main}`,
				fontSize: "1em",
			}}
		>
			<CloseIcon
				onClick={onClose}
				className="close-icon"
				sx={{
					...iconStyles,
					fontSize: isSmallScreen ? "1.6em !important" : "1.7em !important",
					position: "absolute",
				}}
			/>
			<LanguageModalMobile onClose={onClose} />
		</div>
	);
}

export default MenuMobileModal;
