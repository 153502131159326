import { isMobile } from "react-device-detect";
import { selectScreenSize } from "../../store/reducers/userSlice";
import "./filter-expanded.css";
import { Formik } from "formik";
import { useSelector } from "react-redux";

function FormikWrapper({ formikRef, children, initialValues, onSubmit }) {
    const screenSize = useSelector(selectScreenSize);
    const isSmallScreen = screenSize === "small" || isMobile;

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <form
          onSubmit={formikProps.handleSubmit}
          className="form-wrapper"
          style={{ marginLeft: !isSmallScreen && " 49px"}}
        >
          {typeof children === "function" ? children(formikProps) : children}
        </form>
      )}
    </Formik>
  );
}

export default FormikWrapper;
