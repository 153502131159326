import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNewOrdersCount } from '../../../store/reducers/adminSlice';
import axios from 'axios';
import { DATA_STATE, SERVER_URL } from '../../../helpers/app.constants';
import { setDataState } from '../../../store/reducers/notificationSlice';
import socket from '../../../helpers/socket';

const useOrdersCount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchNewOrdersCount = async () => {
      dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
      try {
        const response = await axios.get(`${SERVER_URL}order/order-count`);

        if (response.data.success) {
          dispatch(setNewOrdersCount(response.data.newOrdersCount)); 
        } else {
          console.error('Error: No success flag in response.');
        }
      } catch (error) {
        console.error('Failed to fetch new orders count:', error);
      } finally {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      }
    };

    fetchNewOrdersCount();

    socket.on('newOrdersCountUpdated', (count) => {
      dispatch(setNewOrdersCount(count)); 
    });

    return () => {
      socket.off('newOrdersCountUpdated'); 
    };
  }, [dispatch]);
};
export default useOrdersCount;

