import "./language-component.css"
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	setLanguage,
	LANGUAGE_OPTIONS,
} from "../../../store/reducers/userSlice";
import { useTheme } from "@emotion/react";
import { ReactComponent as EnglishIcon } from "../../../media/language/english.svg";
import { ReactComponent as SerbianIcon } from "../../../media/language/serbian.svg";
import LanguageModal from "./LanguageModal";


const languageIcons = {
	[LANGUAGE_OPTIONS.ENGLISH]: EnglishIcon,
	[LANGUAGE_OPTIONS.SERBIAN]: SerbianIcon,
};

function LanguageSelect({ isModalOpen, onToggleModal }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const currentLanguage = useSelector((state) => state.user.language);
	const modalRef = useRef(null);
	const [rotationDegree, setRotationDegree] = useState(0);

	const handleLanguageChange = (language) => {
		setRotationDegree((prevDegree) => prevDegree + 360);
		dispatch(setLanguage({ language }));
		onToggleModal(false);
	};

	const toggleModal = () => {
		onToggleModal(!isModalOpen); 
	};

	const CurrentLanguageIcon = languageIcons[currentLanguage] || EnglishIcon;

	return (
		<div className="language-desktop-select">
			<div
				 className="language-desktop-selected "
				onClick={toggleModal}
				style={{
					border: `1px solid ${theme.palette.customOpposite.main}`,
					transform: `rotate(${rotationDegree}deg)`,
				}}
			>
				<CurrentLanguageIcon className="language-desktop-selected-icon" />
			</div>
			{isModalOpen && (
				<div ref={modalRef} className="modal-container">
						<LanguageModal
							onLanguageSelect={handleLanguageChange}
							CurrentLanguageIcon={CurrentLanguageIcon}
							onClose={() => onToggleModal(false)}
						/>
				</div>
			)}
		</div>
	);
}

export default LanguageSelect;
