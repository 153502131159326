import "./filter-expanded.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import {
  createFilterArray,
  extractUniqueValues,
} from "../../helpers/filterUtils";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../store/reducers/userSlice.js";
import FilterButtons from "./FilterButtons";
import FilterGroup from "./FilterGroup";
import SearchInput from "../header/components/SearchInput.js";
import { Divider } from "@mui/material";
import { selectAllProducts } from "../../store/reducers/productSlice.js";

function FiltersFormik({ searchQuery, setSearchQuery, setIsExpanded, tags, colors }) {
  const { values, setFieldValue, submitForm, resetForm } = useFormikContext();
  const theme = useTheme();
  const language = useSelector(selectActiveLanguage);
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const products = useSelector(selectAllProducts);

  const sizes = products ? extractUniqueValues(products, "size") : [];
  const heelHeights = products
    ? extractUniqueValues(products, "heelHeight")
    : [];

    const filterArray = createFilterArray(
      sizes,
      heelHeights,
      language,
      tags,
      colors
    );
    
  const initialValues = useMemo(
    () => ({
      filters: [],
      minPriceFormik: 0,
      maxPriceFormik: 50000,
      searchQuery: isSmallScreen ? searchQuery : "",
    }),
    [isSmallScreen, searchQuery]
  );

  const [minPrice, setMinPrice] = useState(initialValues.minPriceFormik);
  const [maxPrice, setMaxPrice] = useState(initialValues.maxPriceFormik);

  const [expandedSections, setExpandedSections] = useState(
    filterArray.reduce((acc, _, index) => {
      acc[index] = true; // Set each section's state to true initially
      return acc;
    }, {})
  );

  // Toggle a specific section's expansion state
  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };



const handleReset = () => {
  resetForm(); 
  setMinPrice(0);
  setMaxPrice(50000);

  if (isSmallScreen) {
    if (searchQuery !== "") {
      setSearchQuery(""); 
    } else {
      submitForm();
    }
  } else {
    setFieldValue("searchQuery", ""); 
    submitForm();
  }
  setIsExpanded(false);  
};


  const handleApply = () => {
    submitForm();
    setIsExpanded(false);  
  };

  const adjustHeight = useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    const height = isSmallScreen ? `calc(100vh - 37vh)` : `calc(100vh - 150px)`;
    document.querySelector(".filter-expanded-content").style.height = height;
  }, [isSmallScreen]);

  useEffect(() => {
    adjustHeight();
    window.addEventListener("resize", adjustHeight);
    return () => {
      window.removeEventListener("resize", adjustHeight);
    };
  }, [adjustHeight]);

  return (
    <div
      className="formik-container"
      style={{
        backgroundColor: theme.palette.primary.main1,
        borderRight: `1px solid ${theme.palette.customOppositeLighter.main}`,
        marginLeft: isSmallScreen ? "" : "0.5px",
      }}
    >
      <div className="filter-expanded-content">
        {!isSmallScreen && (
          <div className="filter-search-container">
            <div className="filter-search">
              <SearchInput
                value={values.searchQuery || ""}
                onChange={(e) => setFieldValue("searchQuery", e.target.value)}
                onSubmit={submitForm}
              />
            </div>
            <Divider
              sx={{
                width: "85%",
                backgroundColor: theme.palette.customOppositeLighter.main,
                margin: "auto",
              }}
              orientation="horizontal"
              variant="middle"
              flexItem
            />
          </div>
        )}

        {filterArray.map((item, index) => (
          <FilterGroup
            key={index}
            item={item}
            values={values}
            setFieldValue={setFieldValue}
            //submitForm={submitForm}
            minPrice={minPrice}
            maxPrice={maxPrice}
            handleMinPriceChange={setMinPrice}
            handleMaxPriceChange={setMaxPrice}
            language={language}
            isSmallScreen={isSmallScreen}
            isLastItem={index === filterArray.length - 1}
            isExpanded={expandedSections[index]}  
            onToggle={() => toggleSection(index)}
          />
        ))}
      </div>
      <FilterButtons
        translatedLanguages={{
          resetFilters: "Reset",
          applyFilters: "Apply",
        }}
        handleReset={handleReset}
        handleApply={handleApply}
        isSmallScreen={isSmallScreen}
      />
    </div>
  );
}

export default FiltersFormik;
