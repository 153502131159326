import "./qa-section.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { selectActiveLanguage } from "../../../store/reducers/userSlice.js";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import { translations } from "../../../helpers/translations.js";
import { CONTACT_INFO } from "../../../helpers/app.constants.js";

function QAsection() {
  const [expandedSection, setExpandedSection] = useState(null);
  const location = useLocation();
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};

  const sections = [
    {
      id: "not-in-stock",
      title:
        translatedLanguages.outOfStockQuestionAbout ||
        "Product I want to order is not in stock.",
    },
    {
      id: "delivery",
      title: translatedLanguages.delivery || "Delivery.",
    },
    {
      id: "payment-methods",
      title: translatedLanguages.paymentMethods || "Payment Methods",
    },
    {
      id: "privacy-policy",
      title: translatedLanguages.privacyPolicy || "Privacy Policy",
    },
    {
      id: "terms-conditions",
      title: translatedLanguages.terms || "Terms and Conditions",
    },
  ];

  const email = CONTACT_INFO.SUPPORT_EMAIL;
  const phone = CONTACT_INFO.SUPPORT_PHONE;
  const outOfStockTimeframe = CONTACT_INFO.OUT_OF_STOCK_TIMEFRAME;
  const generalTimeframe = CONTACT_INFO.GENERAL_TIMEFRAME;
  const internationalTimeframe = CONTACT_INFO.INTERNATIONAL_TIMEFRAME;
  const companyLegalName = CONTACT_INFO.COMPANY_NAME;
  const companyPIB = CONTACT_INFO.PIB;
  const companyRegistrationNumber = CONTACT_INFO.REGISTRATION_NUMBER;


  const handleSectionClick = (id) => {
    if (expandedSection === id) {
      setExpandedSection(null);
  
      const contentElement = document.getElementById(id)?.querySelector(".qa-content");
      if (contentElement) {
        contentElement.style.maxHeight = "0px";
      }
    } else {
      const allSections = document.querySelectorAll(".qa-content");
      allSections.forEach((contentElement) => {
        contentElement.style.maxHeight = "0px"; 
      });
  
      setExpandedSection(id);
  
      const sectionElement = document.getElementById(id);
      if (sectionElement) {
        const contentElement = sectionElement.querySelector(".qa-content");
        contentElement.style.maxHeight = contentElement.scrollHeight + "px"; 
  
        setTimeout(() => {
          const sectionTop = sectionElement.getBoundingClientRect().top + window.scrollY;
          const scrollOffset = 150; 
  
          if (sectionTop - scrollOffset < window.scrollY || sectionTop > window.scrollY + window.innerHeight) {
            window.scrollTo({
              top: sectionTop - scrollOffset,
              behavior: "smooth",
            });
          }
        }, 300); 
      }
    }
  };

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash) {
        setExpandedSection(hash); 
        setTimeout(() => {
            const sectionElement = document.getElementById(hash);
            if (sectionElement) {
                const sectionTop = sectionElement.getBoundingClientRect().top + window.scrollY;
                const offset = 100; 
                window.scrollTo({
                    top: sectionTop - offset,
                    behavior: "smooth",
                });
            }
        }, 100); 
    }
}, [location]);


  const qaContentMapping = {
    "terms-conditions": "termsAndConditions",
    "privacy-policy": "privacyPolicyQA",
    "payment-methods": "paymentMethodQA",
    delivery: "deliveryMethodQA",
    "not-in-stock": "outOfStockQA",
  };

  return (
    <div className="qa-section">
      {sections.map((section) => (
        <div key={section.id} id={section.id} className="qa-item">
          <div
            className={`qa-header ${
              expandedSection === section.id ? "expanded" : ""
            }`}
            onClick={() => handleSectionClick(section.id)}
          >
            <span>{section.title}</span>
            <KeyboardArrowRightTwoToneIcon
              style={{
                transform:
                  expandedSection === section.id
                    ? "rotate(90deg)"
                    : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </div>
          <div
            className={`qa-content ${
              expandedSection === section.id ? "expanded" : ""
            }`}
          >
            {qaContentMapping[section.id] && (
              <p
                dangerouslySetInnerHTML={{
                  __html: translatedLanguages[qaContentMapping[section.id]]
                    ?.replace("[Email]", email) 
                    ?.replace("[PhoneNumber]", phone) 
                    ?.replace("[OutOfStockTimeFrame]", outOfStockTimeframe)
                    ?.replace(/\[GeneralTimeframe\]/g, generalTimeframe)
                    ?.replace("[InternationalTimeframe]", internationalTimeframe)
                    ?.replace(/\[CompanyLegalName\]/g, companyLegalName)
                    ?.replace("[PIB]", companyPIB)
                    ?.replace("[RegistrationNumber]", companyRegistrationNumber)
                    .replace(/\n/g, "<br />"), 
                }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default QAsection;
