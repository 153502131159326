import "./App.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectScreenSize } from "./store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreation } from "./theme/themeDesign";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import LoadingModal from "./components/other/LoadingModal";
import NotificationContainer from "./components/other/NotificationContainer";
import ScreenSizeListener from "./helpers/ScreenSizeListener";
import ShopPage from "./components/pages/ShopPage";
import ProductsDetailedPage from "./components/pages/ProductsDetailedPage";
import CartCheckout from "./components/pages/CartCheckout";
import AboutUsPage from "./components/pages/AboutUsPage";
import AdminLogin from "./adminPanel/pages/AdminLogin";
import MainLayout from "./layout/MainLayout";
import AdminLayout from "./layout/AdminLayout";
import AdminPanel from "./adminPanel/pages/AdminPanel";
import ProtectedRoute from "./adminPanel/ProtectedRoute";
import AdminPanelEditProduct from "./adminPanel/pages/AdminPanelEditProduct";
import { ADMIN_PANEL_TABS } from "./adminPanel/components/adminTabs";
import AdminPanelOrderDetails from "./adminPanel/components/adminPanelOrderDetails/AdminPanelOrderDetails";
import useGetAllProducts from "./helpers/hooks/useGetAllProducts";

function App() {
  const [theme] = useState(themeCreation());
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  useGetAllProducts();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout isSmallScreen={isSmallScreen} />}>
            <Route path="/*" element={<HomePage />} />
            <Route path="/shop/*" element={<ShopPage />} />
            <Route
              path="/shop/product/:id/*"
              element={<ProductsDetailedPage />}
            />
            <Route path="/cart-checkout" element={<CartCheckout />} />
            <Route path="/about" element={<AboutUsPage />} />
          </Route>

          <Route element={<AdminLayout />}>
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/admin-panel"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            >
              {ADMIN_PANEL_TABS.map((tab) => (
                <Route
                  key={tab.key}
                  path={tab.path}
                  element={<ProtectedRoute>{tab.component}</ProtectedRoute>}
                />
              ))}
              <Route
                path="order-details/:id"
                element={
                  <ProtectedRoute>
                    <AdminPanelOrderDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="product-edit/:id"
                element={
                  <ProtectedRoute>
                    <AdminPanelEditProduct />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Routes>

        <LoadingModal />
        <NotificationContainer />
        <ScreenSizeListener />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
