import axios from "axios";

import {
  SERVER_URL,
  DATA_STATE,
  NOTIFICATION_TYPES,
} from "../helpers/app.constants";

import {
  setDataState,
  displayNotification,
} from "../store/reducers/notificationSlice";
import { selectToken } from "../store/reducers/userSlice";

export const addProduct = (product) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}product/addproduct`, product, {
        headers: {},
      })
      .then(() => {
        const notificationPayload = {
          text: "Product added!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve();
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occurred!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const editProduct = (productId, updatedProductData) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  // Don't use JSON.stringify for tags, colors, and sizes, keep them as arrays
  if (updatedProductData.get("tags")) {
    const tagsArray = updatedProductData
      .get("tags")
      .split(",")
      .map((tag) => tag.trim().toLowerCase());
    updatedProductData.set("tags", tagsArray);  // Send as an array
  }

  if (updatedProductData.get("colors")) {
    const colorsArray = updatedProductData
      .get("colors")
      .split(",")
      .map((color) => color.trim().toLowerCase());
    updatedProductData.set("colors", colorsArray);  // Send as an array
  }

  if (updatedProductData.get("size")) {
    const sizeArray = updatedProductData
      .get("size")
      .split(",")
      .map((size) => size.trim());
    updatedProductData.set("size", sizeArray);  // Send as an array
  }

  if (updatedProductData.get("outOfStockSizes")) {
    const outOfStockSizesArray = updatedProductData
      .get("outOfStockSizes")
      .split(",")
      .map((size) => size.trim());
    updatedProductData.set("outOfStockSizes", outOfStockSizesArray);  // Send as an array
  }

  updatedProductData.append("productId", productId);

  try {
    const response = await axios({
      method: "put",
      url: `${SERVER_URL}product/editproduct`,
      data: updatedProductData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch(
      displayNotification({
        text: "Product updated!",
        type: NOTIFICATION_TYPES.SUCCESS,
      })
    );

    return response.data; // Return the response for chaining with `.then()`
  } catch (error) {
    console.error("Error updating product:", error);
    dispatch(
      displayNotification({
        text: "Error occurred!",
        type: NOTIFICATION_TYPES.ERROR,
      })
    );
  } finally {
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
  }
};



export const getProduct = (id) => (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}product/getproduct`, { id })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occured!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const deleteProduct = (id) => (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}product/removeproduct`, { productId: id })
      .then((response) => {
        const notificationPayload = {
          text: "Product deleted!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occured!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const deleteProductImage = (id, field) => (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}product/removeproductimage`, {
        id: id,
        imageType: field,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const saveFeaturedProducts = (featuredProducts) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  console.log("Featured Products Before Formatting:", featuredProducts); // Debugging

  // Safely map over the categories ensuring each value is an array
  const featuredProductIds = Object.keys(featuredProducts).reduce((acc, category) => {
    if (Array.isArray(featuredProducts[category])) { // Ensure it's an array
      acc[category] = featuredProducts[category].map((product) => product._id);
    } else {
      console.warn(`Category '${category}' is not an array and will be skipped.`);
    }
    return acc;
  }, {});

  console.log("Formatted Featured Product IDs:", featuredProductIds); // For debugging

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}product/savefeaturedproducts`, featuredProductIds, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        const notificationPayload = {
          text: "Featured products saved!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve();
      })
      .catch((error) => {
        console.error("Error response from server:", error.response?.data); // Log full error response

        const notificationPayload = {
          text: "Error occurred while saving featured products!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};



export const markOrderAsShipped =
  (orderId, receiptNumber) => async (dispatch) => {
    dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
    return new Promise((resolve, reject) => {
      axios
        .post(`${SERVER_URL}admin/setorderasshipped`, {
          orderId,
          receiptNumber,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
    });
  };

export const fetchReceiptNumber = () => async (dispatch) => {
  try {
    const response = await axios.get(`${SERVER_URL}admin/receiptnumber`);

    return response.data.receiptNumber || "";
  } catch (error) {
    console.error("Error fetching receipt number:", error);
    dispatch(
      displayNotification({
        text: "Failed to fetch receipt number",
        type: NOTIFICATION_TYPES.ERROR,
      })
    );
    throw error;
  }
};

export const dispatchFetchOrder = (id) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/fetchorder`, {
        id,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const deleteOrder = (id) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}order/deleteorder`, {
        orderId: id,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const updateProductStock = (productId, outOfStock) => (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}product/updateproductstock`, {
        productId,
        outOfStock,
      })
      .then((response) => {
        resolve(response.data.message);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const dispatchGetAllGalleryImages = () => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}admin/getgalleryimages`)
      .then((response) => {
        const galleryImages = response.data.galleryImages || [];
        // Resolve with the galleryImages array (objects with _id and imageURL)
        resolve(galleryImages); 
      })
      .catch((error) => {
        console.error('Failed to fetch gallery images:', error);
        reject(error); 
      })
      .finally(() => {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      });
  });
};


export const addGalleryImage = (name, file) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/addgalleryimage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const notificationPayload = {
          text: "Image added!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occurred while adding the image!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const deleteGalleryImage = (id) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/deletegalleryimage`, { imageId: id })
      .then((response) => {
        const notificationPayload = {
          text: "Image deleted!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occurred while deleting the image!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const saveTelegramConfig = (telegramBotToken, telegramChatId) => async (dispatch, getState) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  const token = selectToken(getState());
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${SERVER_URL}admin/savetelegramconfig`, 
        { telegramBotToken, telegramChatId },
        { 
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then((response) => {
        const notificationPayload = {
          text: "Telegram configuration saved successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        dispatch({ type: 'SAVE_TELEGRAM_CONFIG_SUCCESS', payload: response.data });
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occurred while saving the Telegram configuration!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        dispatch({ type: 'SAVE_TELEGRAM_CONFIG_FAIL', payload: error });
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};


export const fetchTelegramConfig = () => async (dispatch, getState) => {
  const token = selectToken(getState());

  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_URL}admin/gettelegramconfig`, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      })
      .then((response) => {
        const { telegramBotToken, telegramChatId } = response.data;
        resolve({ telegramBotToken, telegramChatId });
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error occurred while fetching the Telegram configuration!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      });
  });
};

export const addTag = (tagName) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/addtag`, { name: tagName }) // Server URL must be set properly
      .then((response) => {
        const notificationPayload = {
          text: "Tag added successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data.tag);  // Resolve with the added tag object
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error adding tag!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const editTag = (tagId, newTagName) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/edittag`, { id: tagId, name: newTagName })
      .then((response) => {
        const notificationPayload = {
          text: "Tag updated successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error updating tag!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const deleteTag = (tagId) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/deletetag`, { id: tagId })
      .then((response) => {
        const notificationPayload = {
          text: "Tag deleted successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error deleting tag!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const getTags = () => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  try {
    const response = await axios.get(`${SERVER_URL}admin/gettags`);
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    return response.data.tags;  // Return tags directly
  } catch (error) {
    const notificationPayload = {
      text: "Error fetching tags!",
      type: NOTIFICATION_TYPES.ERROR,
    };
    dispatch(displayNotification(notificationPayload));
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    throw error;  // Throw error to handle it later
  }
};


export const addColor = (colorName, colorHex) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/addcolor`, { name: colorName, hex: colorHex })
      .then((response) => {
        const notificationPayload = {
          text: "Color added successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data.color);  // Resolve with the added color object
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error adding color!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const editColor = (colorId, newColorName, newColorHex) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/editcolor`, { id: colorId, name: newColorName, hex: newColorHex })
      .then((response) => {
        const notificationPayload = {
          text: "Color updated successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error updating color!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const deleteColor = (colorId) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/deletecolor`, { id: colorId })
      .then((response) => {
        const notificationPayload = {
          text: "Color deleted successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error deleting color!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const getColors = () => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  try {
    const response = await axios.get(`${SERVER_URL}admin/getcolors`);
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    return response.data.colors;  // Return the colors data directly
  } catch (error) {
    const notificationPayload = {
      text: "Error fetching colors!",
      type: NOTIFICATION_TYPES.ERROR,
    };
    dispatch(displayNotification(notificationPayload));
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    throw error;  // Throw the error for handling elsewhere
  }
};


export const addFeaturedCategory = (category) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/addfeaturedcategory`, { id: category.id, name: category.name })
      .then((response) => {
        const notificationPayload = {
          text: "Category added to featured!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error adding category to featured!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};

export const removeFeaturedCategory = (categoryId) => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_URL}admin/removefeaturedcategory`, { id: categoryId })
      .then((response) => {
        const notificationPayload = {
          text: "Category removed from featured!",
          type: NOTIFICATION_TYPES.SUCCESS,
        };
        dispatch(displayNotification(notificationPayload));
        resolve(response.data);
      })
      .catch((error) => {
        const notificationPayload = {
          text: "Error removing category from featured!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
        reject(error);
      })
      .finally(() => dispatch(setDataState(DATA_STATE.DATA_STATE_OK)));
  });
};


export const getFeaturedCategories = () => async (dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  try {
    const response = await axios.get(`${SERVER_URL}admin/getfeaturedcategories`);
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    return response.data.featuredCategories;  // Return categories directly
  } catch (error) {
    const notificationPayload = {
      text: "Error fetching featured categories!",
      type: NOTIFICATION_TYPES.ERROR,
    };
    dispatch(displayNotification(notificationPayload));
    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    throw error;  // Throw error to handle it later
  }
};

