import "./product-container.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { applyDiscount } from "../../helpers/hooks/useApplyDiscount.js";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../store/reducers/userSlice";
import { translations } from "../../helpers/translations.js";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

function ProductContainer({ product }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const [isHoveredCart, setIsHoveredCart] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const images = [product.image, product.image2, product.image3].filter(
    Boolean
  );

  const handleMouseEnterImage = () => setCurrentImageIndex(1);
  const handleMouseLeaveImage = () => setCurrentImageIndex(0);
  const handleNextImage = () =>
    setCurrentImageIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
  const handlePrevImage = () =>
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));

  const handleCartClick = () => {
    navigate(`/shop/product/${product._id}`, { state: { product } });
  };
  let hoverTimeout;
  const handleMouseEnterCart = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => setIsHoveredCart(true), 10);
  };

  const handleMouseLeaveCart = () => {
    clearTimeout(hoverTimeout);
    hoverTimeout = setTimeout(() => setIsHoveredCart(false), 10);
  };

  const discountedPrice = product.tags.includes("sale")
    ? applyDiscount(product.price, product.discountAmount)
    : product.price;
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100); 
  }, []);
  const iconStyles = {
    stroke: theme.palette.primary.main1,
    strokewidth: "1px",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    fontSize: "2em",
    color: theme.palette.customOpposite.main,
  };
  return (
    <div
    className={`product-container ${isVisible ? "visible" : ""}`}
      style={{
        padding: isSmallScreen ? "2vh 0" : "0 2vh",
        maxWidth: isSmallScreen ? "280px" : "400px",
      }}
    >
      <div
        className="image-wrapper"
        onMouseEnter={handleMouseEnterImage}
        onMouseLeave={handleMouseLeaveImage}
      >
        <img
          src={images[currentImageIndex]}
          alt={product.name}
          className="product-image"
          loading="lazy" 
        />

        <div className="prev-button" onClick={handlePrevImage}>
          <ArrowBackIosIcon />
        </div>

        <div className="next-button" onClick={handleNextImage}>
          <ArrowForwardIosIcon />
        </div>
      </div>

      <h3 className="product-title" onClick={handleCartClick}>
        {product.name}
        {product.tags.includes("new") && (
          <span
            className="tag-new"
            style={{ padding: isSmallScreen ? "2px" : "5px" }}
          >
            {translatedLanguages.filters.new}
          </span>
        )}
      </h3>

      <div className="price-cart-container" onClick={handleCartClick}>
        <div className="product-price">
          {product.tags.includes("sale") ? (
            <div className="product-price-column">
              <span className="strikethrough">{product.price.toLocaleString("de-DE")}</span>
              <span style={{fontSize:"1.1em"}}>{discountedPrice.toLocaleString("de-DE")},00 RSD</span>
            </div>
          ) : (
            <span>{product.price.toLocaleString("de-DE")},00 RSD</span>
          )}
        </div>

        <div
          className="cart-icon-container"
          onMouseEnter={handleMouseEnterCart}
          onMouseLeave={handleMouseLeaveCart}
        >
          {isHoveredCart ? (
            <ShoppingCartIcon sx={iconStyles} />
          ) : (
            <ShoppingCartOutlinedIcon sx={iconStyles} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductContainer;
