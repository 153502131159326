export function applyDiscount(price, discountPercentage) {
    const discountAmount = (price * discountPercentage) / 100;
    const discountedPrice = price - discountAmount;
    return [discountedPrice, discountAmount];
  }
  
  export const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    return new Date(year, month - 1, day, ...timePart.split(":").map(Number));
  };
  
  export const calculateTotalPrice = (products, fetchedProducts) => {
    let total = 0;
    products.forEach((product) => {
      const fullProduct = fetchedProducts.find((p) => p._id === product.productID);
      if (fullProduct) {
        const [discountedPrice] = applyDiscount(fullProduct.price, fullProduct.discountAmount);
        total += discountedPrice * product.count;
      }
    });
    return total;
  };
  