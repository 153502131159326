import "./filter-component.css";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterExpanded from "./FilterExpanded";
import {
  selectIsFilterExpanded,
  setFilteredProducts,
  setFilterExpanded,
  setLoading,
  setNoProductsFound,
} from "../../store/reducers/filterSlice";
import TuneIcon from "@mui/icons-material/Tune";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import useClickAway from "../../helpers/hooks/useClickAway";
import axios from "axios";
import { SERVER_URL } from "../../helpers/app.constants";
import FormikWrapper from "./FormikWrapper";
import {
  extractUniqueValues,
} from "../../helpers/filterUtils";
import { selectAllProducts } from "../../store/reducers/productSlice";
import { handleFilterSubmit } from "../../helpers/filterUtilsSubmit";
import useGetTags from "../../helpers/hooks/useGetTags.js";
import useGetColors from "../../helpers/hooks/useGetColors.js";

function FilterComponent() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const formikRef = useRef(null);
  const filterRef = useRef(null);
  const navigate = useNavigate();
  const products = useSelector(selectAllProducts);
  const tags = useGetTags(); 
  const colors = useGetColors(); 
  
  const isExpanded = useSelector(selectIsFilterExpanded);

  const sizes = extractUniqueValues(products, "size");
  const heelHeights = extractUniqueValues(products, "heelHeight");

  const initialValues = useMemo(
    () => ({
      filters: [],
    }),
    []
  );

  const handleClickExpanded = () => {
    dispatch(setFilterExpanded(!isExpanded));
    if (!isExpanded) {
      navigate("/shop", { state: { filterExpanded: true } });
    }
  };

  useClickAway(filterRef, () => {
    if (isExpanded) {
      dispatch(setFilterExpanded(false));
    }
  });

  const submitFormWithTag = useCallback(
    async (filterTag) => {
      if (filterTag && formikRef.current) {
        formikRef.current.setFieldValue('filters', [filterTag], false);
        dispatch(setLoading(true));
        try {
          const response = await axios.post(
            `${SERVER_URL}product/filterproducts`,
            { tags: [filterTag] }
          );
          dispatch(setFilteredProducts(response.data.products || []));
          dispatch(setLoading(false));
        } catch (error) {
          console.error("Error filtering products with tag:", error);
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch]
  );
  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterTag = queryParams.get("filter");
    
    if (!filterTag) {
      return;
    }
  
    if (formikRef.current) {
      const currentFilters = formikRef.current.values.filters;
  
      if (!currentFilters.includes(filterTag)) {
        const updatedFilters = currentFilters.filter(f => !tags.includes(f)); 
        formikRef.current.setFieldValue(
          "filters",
          [...updatedFilters, filterTag],  
          false
        );
      }

      submitFormWithTag(filterTag);
    } else {
      console.warn("Formik ref is not available yet.");
    }
  }, [location, submitFormWithTag]);
  

  const handleSubmit = (values) => {
    handleFilterSubmit(
      values,
      dispatch,
      heelHeights,
      sizes,
      tags.map(tag => tag.name),  
      colors.map(color => color.name),  
      navigate,
    );
  };

  const iconStyles = (isExpanded) => ({
    fontSize: "1.75em",
    color: theme.palette.customOpposite.main,
    transition: "transform 0.3s ease-in-out",
    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    cursor: "pointer",
    stroke: "white",
    strokeWidth: 0.5,
  });

  return (
    <div
      ref={filterRef}
      className="filter-bar"
      style={{
        borderRight: `1px solid ${theme.palette.customOppositeLighter.main}`,
        borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
        backgroundColor: theme.palette.primary.main1,
      }}
    >
      <div className="filter-divider" onClick={handleClickExpanded}>
        <TuneIcon sx={iconStyles(isExpanded)} />
      </div>
      <FormikWrapper
        formikRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {isExpanded && (
          <div
            className="expanded-filter"
            style={{
              borderLeft: `1px solid ${theme.palette.customOppositeLighter.main}`,
            }}
          >
            <FilterExpanded 
            sizes={sizes}
            heelHeights={heelHeights}
            tags={tags.map(tag => tag.name)} 
            colors={colors.map(color => color.name)} 
            setIsExpanded={handleClickExpanded} />
          </div>
        )}
      </FormikWrapper>
      <div className="filter-divider" onClick={handleClickExpanded}>
        <KeyboardArrowRightTwoToneIcon sx={iconStyles(isExpanded)} />
      </div>
    </div>
  );
}

export default React.memo(FilterComponent);
