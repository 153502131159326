import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  Checkbox,
  Tooltip,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import { Button } from "react-admin";
import { saveFeaturedProducts } from "../adminPanel.actions";

const FeaturedProducts = ({
  featuredProducts,
  featuredCategories = [], 
  handleProductSelection,
  handleProductToggle,
  handleProductClick,
  openDeleteModal,
  isScreenSmall,
  openCategoryModal,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [expandedSections, setExpandedSections] = useState(
    featuredCategories.reduce((acc, category) => {
      acc[category.name] = false;
      return acc;
    }, {})
  );

  const mergedFeaturedProducts = useMemo(() => {
    const merged = { ...featuredProducts };

    Object.keys(featuredProducts).forEach((category) => {
      if (
        featuredProducts[category] &&
        Array.isArray(featuredProducts[category])
      ) {
        merged[category] = featuredProducts[category];
      }
    });

    return merged;
  }, [featuredProducts]);

  const handleSectionToggle = useCallback(
    (category) => {
      setExpandedSections((prevState) => ({
        ...prevState,
        [category]: !prevState[category],
      }));
    },
    [] 
  );
  

  const handleSaveFeaturedProducts = () => {
    dispatch(saveFeaturedProducts(mergedFeaturedProducts));
  };

  return (
    <Box sx={{ zIndex: 50, position: "relative" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Featured Products</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveFeaturedProducts}
            sx={{ marginLeft: "auto", marginRight: "16px" }}
          >
            Save
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {featuredCategories.length === 0 ? (
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                No categories available
              </Typography>
            ) : (
              featuredCategories.map(({ name }) => (
                <Accordion
                  key={name}
                  expanded={expandedSections[name]}
                  onChange={() => handleSectionToggle(name)}
                >
                  <AccordionSummary
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.main,
                      },
                    }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    {/* Display the category heading */}
                    <Typography variant="h8">
                      {name.charAt(0).toUpperCase() + name.slice(1)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Check if products exist in the category */}
                    {mergedFeaturedProducts[name]?.length > 0 ? (
                      mergedFeaturedProducts[name].map((product) => (
                        <Box
                          key={product._id}
                          sx={{
                            display: "grid",
                            gridTemplateColumns:
                              "0.5fr 0.8fr 2fr 1.2fr 1.2fr 1fr 0.8fr 1fr",
                            gap: 2,
                            alignItems: "center",
                            fontSize: isScreenSmall ? "0.8em" : "0.95em",
                            textAlign: "left",
                            height: "64px",
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                        >
                          <Tooltip title="Remove">
                            <Checkbox
                              sx={{
                                color: theme.palette.customOppositeLighter.main,
                                "&.Mui-checked": {
                                  color: theme.palette.customOpposite.main,
                                },
                              }}
                              checked={featuredProducts[name]?.some(
                                (p) => p._id === product._id
                              )}
                              onChange={() =>
                                handleProductToggle(product, name)
                              }
                            />
                          </Tooltip>
                          <Box
                            component="span"
                            sx={{ display: "flex", justifyContent: "left" }}
                          >
                            <img
                              src={product.image}
                              alt={product.name}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                              }}
                            />
                          </Box>
                          <Typography sx={{ overflow: "hidden" }}>
                            {product.name}
                          </Typography>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {product.size.join(", ")}
                          </Typography>
                          <Typography
                            sx={{ overflow: "hidden", textAlign: "center" }}
                          >
                            {product.heelHeight}
                          </Typography>
                          <Typography
                            sx={{ overflow: "hidden", textAlign: "center" }}
                          >
                            {product.discountAmount > 0 ? (
                              <span style={{ color: "red" }}>
                                {(
                                  product.price -
                                  (product.price * product.discountAmount) / 100
                                ).toLocaleString("de-DE")}
                                ,00
                              </span>
                            ) : (
                              `${product.price.toLocaleString("de-DE")},00`
                            )}
                          </Typography>
                          <Typography
                            sx={{ overflow: "hidden", textAlign: "center" }}
                          >
                            {product.discountAmount
                              ? `${product.discountAmount}%`
                              : "/"}
                          </Typography>
                          <Box
                            sx={{ justifyContent: "flex-end", display: "flex" }}
                          >
                            <Tooltip title="Edit product">
                              <EditIcon
                                sx={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  fontSize: "1.5em",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleProductClick(product._id);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete product">
                              <DeleteIcon
                                sx={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  fontSize: "1.5em",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDeleteModal(product);
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography>
                        No products available in this category
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FeaturedProducts;
