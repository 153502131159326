import { TextField } from "@mui/material";

const InputFieldFormik = ({ field, meta, type, helperText, sx, ...rest }) => {
  const { value, name, onBlur, onChange } = field;  // Extract field props
  const { error, touched } = meta;  // Extract error and touched from meta

  const customStyles = {
    ...sx,
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "black",  // Custom border color when focused
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "black",  // Custom label color when focused
    },
    "& .Mui-error .MuiOutlinedInput-root fieldset": {
      borderColor: "red",  // Error color when there's an error
    },
    "& .MuiInputBase-input": {
      padding: "10px 12px",  // Input padding
    },
    margin: "10px 0",
  };

  return (
    <TextField
      sx={customStyles}
      type={type || "text"}
      name={name}
      label={rest.label} // Ensure the label is passed as a prop
      onBlur={onBlur}
      onChange={onChange}
      value={value || ""}
      error={!!error && touched}  // Show error only if touched and error exists
      helperText={touched && error ? error : helperText || " "}  // Show error or custom helper text
      {...rest}
    />
  );
};

export default InputFieldFormik;
