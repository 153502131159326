
import Tooltip from "@mui/material/Tooltip";
import CustomButton from "../../../components/other/CustomButton"

const OrderStatus = ({
  order,
  receiptNumber,
  onReceiptNumberChange,
  onMarkAsShipped,
  onDeleteOrder,
}) => (
  <div className="order-status">
    <div className="status-label">
      Status:
      <div className="status-value">
        {order.shipped ? (
          <span className="status-shipped"> &ensp;SENT</span>
        ) : (
          <span className="status-pending">&ensp;ON HOLD</span>
        )}
      </div>
    </div>
    {!order.shipped && (
      <div className="receipt-actions">
        <Tooltip title="Receipt number is required">
          <span >Receipt number:</span>
        </Tooltip>
        <input
          className="receipt-input"
          type="number"
          value={receiptNumber}
          onChange={onReceiptNumberChange}
        />
        <CustomButton
          variant="contained"
          className="hover-style"
          disabled={receiptNumber === ""}
          onClick={onMarkAsShipped}
          textButton="SEND ORDER"
          margin="0 0 0 10px"
          padding="2px"
        />
      </div>
    )}
    {order.shipped && (
      <CustomButton
      deleteButton={true}
        variant="contained"
        className="hover-style"
        onClick={onDeleteOrder}
        textButton="DELETE ORDER"
        margin="2vh 0"
        padding="2px"
        width="140px"
      />
    )}
  </div>
);

export default OrderStatus;
