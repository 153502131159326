// CustomDropdown.js
import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const CustomDropdown = ({
  options,
  selectedValues,
  onChange,
  color,
  style,
}) => {
  const handleChange = (value) => {
    const currentIndex = selectedValues.indexOf(value);
    const newSelectedValues = [...selectedValues];
  
    if (currentIndex === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }
  
    onChange(newSelectedValues);
  };
  

  return (
    <div
      style={{
        width: "100%",
        maxHeight: "300px",
        backgroundColor: "white",
        zIndex: 10,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "4px",
        position: "absolute",
        top: "55px",
        left: "0",
        overflow:"auto",
        ...style,
      }}
    >
      <FormControl fullWidth>
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                checked={selectedValues.indexOf(option.label) > -1} 
                onChange={() => handleChange(option.label)}
                  sx={{
                    "&.Mui-checked": {
                      color: "black",
                    },
                    "&.Mui-checked:hover": {
                      backgroundColor: "transparent",
                    },
                    marginLeft:"10px",
                  }}
                />
              }
              label={
                !color ? ( 
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: option.value,
                        marginRight: "10px",
                        border: "1px solid #ccc",
                      }}
                    ></div>
                    {option.label}
                  </div>
                ) : (
                  option.label 
                )
              }
              sx={{ height: "25px", padding: "5px 0" }}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default CustomDropdown;
