import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getTags } from "../../adminPanel/adminPanel.actions";

const useGetTags = () => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      const fetchedTags = await dispatch(getTags());
      //console.log("Fetched Tags:", fetchedTags); 
      setTags(fetchedTags);
    };

    fetchTags();
  }, [dispatch]);

  return tags;
};

export default useGetTags;
