import { getTranslation } from './translationUtils';

export const createFilterArray = (sizes = [], heelHeights = [], language = 'en', tags = [], colors = []) => [
  {
    title: getTranslation('filters.tags', language),
    filters: tags.map(tag => ({ label: getTranslation(`filters.${tag}`, language), value: tag })),
  },
  {
    title: getTranslation('filters.price', language),
    price: true,
  },
  {
    title: getTranslation('filters.color', language),
    filters: colors.map(color => ({ label: getTranslation(`filters.colors.${color}`, language), value: color })),
  },
  {
    title: getTranslation('filters.heelHeight', language),
    filters: heelHeights.map(heelHeight => ({ label: heelHeight, value: heelHeight })),
  },
  {
    title: getTranslation('filters.size', language),
    filters: sizes.map(size => ({ label: size, value: size })),
  },
];


export const extractUniqueValues = (products, key) => {
  const values = products.reduce((acc, product) => {
    const value = product[key];
    if (Array.isArray(value)) {
      value.forEach((v) => acc.add(v));
    } else if (value) {
      acc.add(value);
    }
    return acc;
  }, new Set());

  return Array.from(values).sort((a, b) => parseFloat(a) - parseFloat(b));
};
