import { Divider } from "@mui/material";
import PriceSlider from "./PriceSlider";
import FilterCheckbox from "./FilterCheckbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FilterGroup = ({
  item,
  values,
  setFieldValue,
  // submitForm,
  handleMinPriceChange,
  handleMaxPriceChange,
  minPrice,
  maxPrice,
  language,
  isLastItem,
  isExpanded,
  onToggle,
}) => {
  return (
    <div className="expanded-content-map">
      <div className="filter-heading-formik" >
		<div className="filter-heading-title" onClick={onToggle}>
        <p className="filter-title">{item.title}</p>
        <ExpandMoreIcon
          style={{
            transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
			color: "gray"
          }}
        />
			</div>
        {isExpanded && (
          <div className="filter-group-content">
            {item.price ? (
              <PriceSlider
                minPrice={minPrice}
                maxPrice={maxPrice}
                handleMinPriceChange={handleMinPriceChange}
                handleMaxPriceChange={handleMaxPriceChange}
                setFieldValue={setFieldValue}
                //submitForm={submitForm}
              />
            ) : (
              item.filters.map((filter, filterIndex) => (
                <FilterCheckbox
                  item={item}
                  key={filterIndex}
                  filter={filter}
                  values={values}
                  setFieldValue={setFieldValue}
                  //submitForm={submitForm}
                  language={language}
                />
              ))
            )}
          </div>
        )}
        {!isLastItem && <Divider sx={{ width: "85%" }} />}
      </div>
    </div>
  );
};

export default FilterGroup;
