import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dispatchGetAllGalleryImages } from '../../adminPanel/adminPanel.actions';
import { DATA_STATE } from '../app.constants';
import { setDataState } from '../../store/reducers/notificationSlice';

export const useGetGalleryImages = () => {
  const dispatch = useDispatch();
  const [galleryImages, setGalleryImages] = useState([]); 

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
        const images = await dispatch(dispatchGetAllGalleryImages());
        setGalleryImages(images); 
      } catch (err) {
        console.error('Error fetching gallery images:', err);
      } finally {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      }
    };

    fetchGalleryImages(); 
  }, [dispatch]);

  return { galleryImages };
};
