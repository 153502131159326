import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";

const CustomButton = ({ 
  disabled, 
  startIcon,
  endIcon,
  onClick,
  margin,
  width,
  display,
  justifyContent,
  padding,
  height,
  fontSize,
  backgroundColor,
  hoverColor,
  hoveBgColor,
  isAdmin,
  deleteButton,
  textButton = "Submit", 
      
}) => {
  const theme = useTheme();
  const buttonLoginStyles = {
    backgroundColor: backgroundColor || theme.palette.primary.main1,
    border: `1px solid ${theme.palette.customOppositeLighter.main}`,
    transition: "background-color 0.3s ease, transform 0.3s ease",
    color: deleteButton ? "red" : "black",
    textTransform: "uppercase",
    padding: padding ? padding :"10px 20px",  
    fontSize: fontSize ? fontSize : "0.8em",     
    margin: margin, 
    width: width,
    height:  height,
    display: display,
    justifyContent: justifyContent,
    fontWeight: isAdmin && "bold",
    "&:hover": {
      backgroundColor:hoveBgColor || theme.palette.secondary.main,  
      border: deleteButton
      ? "1px solid red"  
      : !isAdmin
      ? `1px solid ${theme.palette.customOpposite.main}` 
      : "",
      fontWeight: isAdmin && "bold"
    },
    "&:disabled": {
      backgroundColor: theme.palette.customOppositeLighter.main,
      border: `solid 1px ${theme.palette.primary.opacity80}`,
      color: theme.palette.primary.opacity80,
    },
  };

  return (
    <Button
      type="submit"
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
      sx={buttonLoginStyles}
      startIcon={startIcon}  
      endIcon={endIcon}      
    >
      {textButton}
    </Button>
  );
};

export default CustomButton;
