import "./header-icons.css";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { Divider } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import Badge from "@mui/material/Badge";
import LanguageSelect from "./LanguageSelect";
import CartModal from "./CartModal";
import MenuMobileSelect from "./MenuMobileSelect";
import { selectItemsCount } from "../../../store/reducers/cartSlice";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function HeaderIcons({
	isCartOpen,
	setIsCartOpen,
	isMenuOpen,
	setIsMenuOpen,
	isLanguageModalOpen,
	setIsLanguageModalOpen,
}) {
	const theme = useTheme();
	const itemsCount = useSelector(selectItemsCount);
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const location = useLocation(); 
	
	

	useEffect(() => {
		setIsCartOpen(false); 
	}, [location, setIsCartOpen]); 

	const handleCartToggle = () => {
		setIsCartOpen((prevState) => !prevState);
		if (!isCartOpen) {
			closeAllModalsExcept("cart");
		}
	};

	const handleMenuToggle = () => {
		setIsMenuOpen((prevState) => !prevState);
		if (!isMenuOpen) {
			closeAllModalsExcept("menu");
		}
	};

	const closeAllModalsExcept = (keepOpen) => {
		if (keepOpen !== "cart") setIsCartOpen(false);
		if (keepOpen !== "menu") setIsMenuOpen(false);
		if (keepOpen !== "language") setIsLanguageModalOpen(false);
	};

	return (
		<div
			className="header-icons-container"
			style={{
				flex: isSmallScreen ? "0 0 30%" : "0 0 15%",
				marginRight: isSmallScreen ? "10px" : "",
			}}
		>
			<Badge
				badgeContent={itemsCount}
				sx={{
					"& .MuiBadge-badge": {
						fontSize: "1em",
						backgroundColor: theme.palette.customRed.main,
						color: "white",
						width: "24px",
						height: "24px",
						borderRadius: "50%",
					},
				}}
			>
				{isCartOpen ? (
					<ShoppingBagTwoToneIcon
						onClick={handleCartToggle}
						sx={{
							fontSize: "2em",
							cursor: "pointer",
							color: theme.palette.customOpposite.main,
							stroke: "white",
							strokeWidth: 0.5,
						}}
					/>
				) : (
					<ShoppingBagOutlinedIcon
						onClick={handleCartToggle}
						sx={{
							fontSize: "2em",
							cursor: "pointer",
							color: theme.palette.customOpposite.main,
							stroke: "white",
							strokeWidth: 0.5,
						}}
					/>
				)}
			</Badge>

			<Divider
				style={{
					backgroundColor: theme.palette.customOppositeLighter.main,
				}}
				orientation="vertical"
				variant="middle"
				flexItem
			/>

			{isSmallScreen ? (
				<MenuMobileSelect
					isMenuOpen={isMenuOpen}
					onMenuClick={handleMenuToggle}
					onCloseMenu={() => setIsMenuOpen(false)}
				/>
			) : (
				<LanguageSelect
					isModalOpen={isLanguageModalOpen}
					onToggleModal={setIsLanguageModalOpen}
				/>
			)}

			{isCartOpen && <CartModal onClose={() => setIsCartOpen(false)} />}
		</div>
	);
}

export default HeaderIcons;
