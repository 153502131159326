import "./loading-modal.css";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import { ReactComponent as LoadingSvg } from "../../media/loadingsvg/loading.svg";
import { DATA_STATE } from "../../helpers/app.constants";
import { selectDataState } from "../../store/reducers/notificationSlice";
import { selectScreenSize } from "../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";

const LoadingModal = () => {
	const dataState = useSelector(selectDataState);
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	return (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: (theme) => theme.zIndex.drawer + 1,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
			open={dataState === DATA_STATE.DATA_STATE_LOADING}
		>
			<LoadingSvg
				className="loading-logo"
				style={{
					height: isSmallScreen ? "15vh" : "20vh",
					width: isSmallScreen ? "15vh" : "20vh",
				}}
			/>
		</Backdrop>
	);
};

export default LoadingModal;
