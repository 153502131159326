import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
	name: "cart",
	initialState: {
		itemsCount: 0,
		items: [],
	},
	reducers: {
		incrementItemsCount(state) {
			state.itemsCount += 1;
		},
		addItemToCart(state, action) {
			const { product, size } = action.payload;

			const existingItem = state.items.find(
				(item) => item.product._id === product._id && item.size === size
			);

			if (existingItem) {
				existingItem.count += 1;
			} else {
				state.items.push({ count: 1, product, size });
			}

			state.itemsCount += 1;
		},
		decrementItemsCount(state) {
			state.itemsCount -= 1;
			if (state.itemsCount < 0) {
				state.itemsCount = 0;
			}
		},
		removeItemsFromCart(state, action) {
			const { id, size } = action.payload;
	
			const existingItem = state.items.find(
				(item) => item.product._id === id && item.size === size
			);
		
			if (!existingItem) {
				console.warn(`Item with ID ${id} and size ${size} not found in the cart.`);
				return;
			}
		
			existingItem.count -= 1;
		
			if (existingItem.count === 0) {
				state.items = state.items.filter(
					(item) => !(item.product._id === id && item.size === size)
				);
			}
		
			state.items = [...state.items]; 
		
			state.itemsCount -= 1;
			if (state.itemsCount < 0) {
				state.itemsCount = 0;
			}
		},
		clearCart(state) {
			state.items = [];
			state.itemsCount = 0;
		  },
		
		
	},
});

export const selectItemsCount = (state) => {
	const items = state.cart.items;
	let count = 0;
	items.forEach((item) => {
		count += item.count;
	});
	return count;
};

const applyDiscount = (price, discountPercentage) => {
	return price - (price * discountPercentage) / 100;
};

export const selectTotalCartPrice = (state) => {
	const items = state.cart.items;
	let total = 0;
	items.forEach((item) => {
		const priceToUse = item.product.tags.includes("sale")
			? applyDiscount(item.product.price, item.product.discountAmount)
			: item.product.price;

		total += priceToUse * item.count;
	});
	return Math.ceil(total);
};

export const selectItems = (state) => state.cart.items;

export const {
	incrementItemsCount,
	decrementItemsCount,
	addItemToCart,
	removeItemsFromCart,
	clearCart, 
} = cartSlice.actions;

export default cartSlice.reducer;
