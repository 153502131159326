import "./menu-mobile.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@emotion/react";
import MenuMobileModal from "./MenuMobileModal";

function MenuMobileSelect({ isMenuOpen, onMenuClick, onCloseMenu }) {
	const theme = useTheme();

	return (
		<div className="menu-mobile-select-container">
			<MenuIcon
				onClick={onMenuClick}
				style={{
					fontSize: "2em",
					cursor: "pointer",
					color: theme.palette.customOpposite.main,
				}}
			/>
			{isMenuOpen && <MenuMobileModal onClose={onCloseMenu} />}
		</div>
	);
}

export default MenuMobileSelect;
