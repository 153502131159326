import React from "react";
import "./confirmation-step.css";
import { translations } from "../../../helpers/translations";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
function ConfirmationStep() {
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  return (
    <div
      className="confirmation-step-container"
      style={{
        minHeight: isSmallScreen ? "59vh" : "75vh",
        padding: isSmallScreen ? "7%" : "5%",
      }}
    >
      <div className="checkmark-container" style={{width: isSmallScreen ? "25%" :"20%"}}>
        <svg className="checkmark" viewBox="0 0 52 52">
          <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
          <path className="checkmark-check" fill="none" d="M14 27l7 7 15-15" />
        </svg>
      </div>

      <h2 style={{ width: "100%", textAlign: "center", fontSize:isSmallScreen && "1.1em" }}>
        {translatedLanguages.orderSent}
      </h2>
      <span  style={{ width: "100%", textAlign: "center", fontSize:isSmallScreen && "1.1em" }}>{translatedLanguages.thankYou}</span>
    </div>
  );
}

export default ConfirmationStep;
