import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import {
  addGalleryImage,
  deleteGalleryImage,
  dispatchGetAllGalleryImages,
  saveTelegramConfig,
  fetchTelegramConfig,
} from "../adminPanel.actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomField from "../../components/other/CustomField";
import { displayNotification } from "../../store/reducers/notificationSlice";
import { NOTIFICATION_TYPES } from "../../helpers/app.constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AdminTagManager from "../components/AdminTagManager";
import AdminColorManager from "../components/AdminColorManager ";
import AdminFeaturedCategories from "../components/AdminFeaturedCategories";

const AdminPanelGallery = () => {
  const dispatch = useDispatch();
  const [uploaders, setUploaders] = useState([{ id: 0, image: null }]);
  const [error, setError] = useState("");
  const [telegramBotToken, setTelegramBotToken] = useState("");
  const [telegramChatId, setTelegramChatId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const galleryImages = await dispatch(dispatchGetAllGalleryImages());

        if (galleryImages.length > 0) {
          const fetchedUploaders = galleryImages.map((image, index) => ({
            id: index,
            imageId: image._id,
            image: image.imageURL,
            name: image.name || `Image ${index + 1}`,
          }));

          const totalUploaders = [...fetchedUploaders];
          while (totalUploaders.length < 4) {
            totalUploaders.push({ id: totalUploaders.length, image: null });
          }

          setUploaders(totalUploaders);
        } else {
          console.error("No gallery images found.");
        }
      } catch (error) {
        console.error("Failed to fetch gallery images:", error);
      }
    };

    fetchGalleryImages();
  }, [dispatch]);

  useEffect(() => {
    const fetchTelegramConfigAction = async () => {
      try {
        const response = await dispatch(fetchTelegramConfig());
        setTelegramBotToken(response.telegramBotToken || "");
        setTelegramChatId(response.telegramChatId || "");
      } catch (error) {
        console.error("Failed to fetch Telegram config:", error);
      }
    };

    fetchTelegramConfigAction();
  }, [dispatch]);

  const handleAddUploader = () => {
    if (uploaders.length < 8) {
      setUploaders((prevUploaders) => [
        ...prevUploaders,
        { id: prevUploaders.length, image: null },
      ]);
      setError("");
    } else {
      setError("You can upload up to 8 images only.");
    }
  };

  const handleImageUpload = async (e, id) => {
    const file = e.target.files[0];
    if (file) {
      const localImageURL = URL.createObjectURL(file);
      setUploaders((prevUploaders) =>
        prevUploaders.map((uploader) =>
          uploader.id === id ? { ...uploader, image: localImageURL } : uploader
        )
      );

      try {
        const uploadedImage = await dispatch(
          addGalleryImage(`Image ${id + 1}`, file)
        );

        if (
          uploadedImage &&
          uploadedImage.image &&
          uploadedImage.image.imageURL &&
          uploadedImage.image._id
        ) {
          setUploaders((prevUploaders) =>
            prevUploaders.map((uploader) =>
              uploader.id === id
                ? {
                    ...uploader,
                    image: uploadedImage.image.imageURL,
                    imageId: uploadedImage.image._id,
                  }
                : uploader
            )
          );
        } else {
          console.error("Invalid response format", uploadedImage);
        }
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    }
  };

  const handleRemoveImage = async (id, imageId) => {
    try {
      console.log("Deleting image with imageId:", imageId);

      if (!imageId) {
        console.error("No imageId found for deletion");
        return;
      }

      const response = await dispatch(deleteGalleryImage(imageId));

      if (response) {
        setUploaders((prevUploaders) =>
          prevUploaders.map((uploader) =>
            uploader.id === id
              ? { ...uploader, image: null, imageId: null }
              : uploader
          )
        );
      } else {
        console.error("Failed to delete image on the server");
      }
    } catch (error) {
      console.error("Failed to delete image:", error);
    }
  };

  const handleSaveTelegramConfig = () => {
    if (telegramBotToken && telegramChatId) {
      dispatch(saveTelegramConfig(telegramBotToken, telegramChatId))
        .then(() => {
          const notificationPayload = {
            text: "Telegram configuration saved successfully!",
            type: NOTIFICATION_TYPES.SUCCESS,
          };
          dispatch(displayNotification(notificationPayload));
        })
        .catch((error) => {
          const notificationPayload = {
            text: "Error saving Telegram configuration!",
            type: NOTIFICATION_TYPES.ERROR,
          };
          dispatch(displayNotification(notificationPayload));
        });
    } else {
      const notificationPayload = {
        text: "Both Telegram Bot Token and Chat ID are required!",
        type: NOTIFICATION_TYPES.ERROR,
      };
      dispatch(displayNotification(notificationPayload));
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          p: 5,
          pt: 3,
          width: "100%",
          borderRadius: 1,
          position: "relative",
          overflow: "auto",
        }}
      >
        <Typography variant="h6" mb={2} mt={2}>
          GALLERY
        </Typography>
        <Box
          sx={{
            width: "90%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            m: 2,
          }}
        >
          {error && (
            <Typography
              color="error"
              sx={{ flexGrow: 1, textAlign: "right", mr: 2 }}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUploader}
          >
            Add Another Image
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            width: "100%",
            mb: 3,
          }}
        >
          {uploaders.map((uploader) => (
            <Box
              key={uploader.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "2px dashed #ddd",
                p: 2,
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                minHeight: "250px",
                minWidth: "200px",
                width: "20%",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              <Typography fontWeight="bold" mb={2}>
                {uploader.image
                  ? `Image ${uploader.id + 1}`
                  : `Upload Image ${uploader.id + 1}`}
              </Typography>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id={`upload-image-${uploader.id}`}
                onChange={(e) => handleImageUpload(e, uploader.id)}
              />
              <label htmlFor={`upload-image-${uploader.id}`}>
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    backgroundColor: "#007bff",
                    color: "white",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    "&:hover": {
                      backgroundColor: "#0056b3",
                    },
                  }}
                >
                  Choose File
                </Button>
              </label>

              {uploader.image && (
                <Box sx={{ position: "relative", mt: 2 }}>
                  <img
                    src={uploader.image}
                    alt={`Preview ${uploader.id + 1}`}
                    loading="lazy"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      border: "2px solid #ddd",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <IconButton
                    onClick={() =>
                      handleRemoveImage(uploader.id, uploader.imageId)
                    }
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "red",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
        </Box>

        <Box sx={{ width: "100%", mt: 3 }}>
          <AdminTagManager />
        </Box>
        <Box sx={{ width: "100%", mt: 3 }}>
          <AdminFeaturedCategories />
        </Box>
        <Box sx={{ width: "100%", mt: 3 }}>
          <AdminColorManager />
        </Box>
        <Box sx={{ width: "100%", mt: 3 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="text-message-content"
              id="text-message-header"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography>Telegram Message on Order</Typography>
                <IconButton onClick={openModal} sx={{ marginLeft: 2 }}>
                  <HelpOutlineIcon />
                </IconButton>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography>Enter the Telegram Bot Token:</Typography>
                    <CustomField
                      label="Telegram Chat ID"
                      minWidth="450px"
                      width="450px"
                      size="small"
                      margin="10px 0"
                      value={telegramBotToken}
                      onChange={(e) => setTelegramBotToken(e.target.value)}
                      disabled={true}
                      regular={true}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#007bff",
                      color: "white",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#0056b3",
                      },
                    }}
                    onClick={handleSaveTelegramConfig}
                  >
                    Save
                  </Button>
                </Box>
                <Typography>Enter the Chat ID:</Typography>
                <CustomField
                  label="Telegram Bot Token"
                  minWidth="450px"
                  width="450px"
                  size="small"
                  margin="10px 0"
                  value={telegramChatId}
                  onChange={(e) => setTelegramChatId(e.target.value)}
                  disabled={true}
                  regular={true}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Dialog open={isModalOpen} onClose={closeModal}>
            <DialogTitle>Telegram Setup Instructions</DialogTitle>
            <DialogContent>
              <Typography>
                <strong>Step 1:</strong> Open Telegram and search for the bot
                <strong>@soulandsolebot</strong>.
                <br />
                <strong>Step 2:</strong> Start a chat with the bot by pressing
                the <strong>Start</strong> button.
                <br />
                <strong>Step 3:</strong> Once you start a conversation with the
                bot, we will retrieve your Chat ID for you and save it
                automatically.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdminPanelGallery;
