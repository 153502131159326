import React, { useEffect, useState, useMemo } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Checkbox, IconButton, InputAdornment } from "@mui/material";
import { editProduct } from "../adminPanel.actions";
import InputFieldFormik from "../../components/formik/InputFieldFormik";
import CustomButton from "../../components/other/CustomButton";
import "./admin-panel-add-product.css";
import { DATA_STATE, NOTIFICATION_TYPES } from "../../helpers/app.constants";
import { getProductById } from "../components/hooks/getProductsByIds";
import {
  displayNotification,
  setDataState,
} from "../../store/reducers/notificationSlice";
import { processTags } from "../components/adminUtils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Icon for dropdown button
import CustomDropdown from "../components/CustomDropdown"; // Custom dropdown component
import useGetColors from "../../helpers/hooks/useGetColors"; // Hook to fetch colors
import useGetTags from "../../helpers/hooks/useGetTags"; // Hook to fetch tags

const FORM_FIELDS = {
  NAME: "name",
  PRICE: "price",
  DISCOUNT_AMOUNT: "discountAmount",
  TAGS: "tags",
  COLORS: "colors",
  SIZE: "size",
  HEEL_HEIGHT: "heelHeight",
  OUT_OF_STOCK: "outOfStock",
  OUT_OF_STOCK_SIZES: "outOfStockSizes",
  IMAGE: "image",
  IMAGE2: "image2",
  IMAGE3: "image3",
};

const AdminPanelEditProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productData, setProductData] = useState(null);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [showTagDropdown, setShowTagDropdown] = useState(false);
  const [showColorDropdown, setShowColorDropdown] = useState(false);

  const colors = useGetColors(); 
  const tags = useGetTags();  

  // useEffect(() => {
  //   console.log("Product Data:", productData);
  // }, [productData]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const product = await getProductById(id, dispatch);
        setProductData(product);
      } catch (error) {
        console.error("Failed to load product data:", error);
      }
    };
    fetchProduct();
  }, [id, dispatch]);

  useEffect(() => {
    if (productData) {
      setImage(productData.image ? productData.image : null);
      setImage2(productData.image2 ? productData.image2 : null);
      setImage3(productData.image3 ? productData.image3 : null);
    }
  }, [productData]);

// Updating initialValues to handle arrays as comma-separated strings
const initialValues = useMemo(() => {
  if (!productData) {
    return {
      [FORM_FIELDS.NAME]: "",
      [FORM_FIELDS.PRICE]: "",
      [FORM_FIELDS.DISCOUNT_AMOUNT]: "",
      [FORM_FIELDS.TAGS]: "",
      [FORM_FIELDS.COLORS]: "",
      [FORM_FIELDS.SIZE]: "",
      [FORM_FIELDS.OUT_OF_STOCK_SIZES]: "",
      [FORM_FIELDS.HEEL_HEIGHT]: "",
      [FORM_FIELDS.OUT_OF_STOCK]: false,
      [FORM_FIELDS.IMAGE]: null,
      [FORM_FIELDS.IMAGE2]: null,
      [FORM_FIELDS.IMAGE3]: null,
    };
  }

  // Properly formatting tags and colors
  const cleanedTags = Array.isArray(productData.tags)
    ? productData.tags.join(", ")  // Joining array into comma-separated string
    : productData.tags || "";

  const cleanedColors = Array.isArray(productData.color)
    ? productData.color.join(", ")
    : productData.color || "";

  const cleanedSizes = Array.isArray(productData.size)
    ? productData.size.join(", ")
    : "";

  const cleanedOutOfStockSizes = Array.isArray(productData.outOfStockSizes)
    ? productData.outOfStockSizes.join(", ")
    : "";

  return {
    [FORM_FIELDS.NAME]: productData.name || "",
    [FORM_FIELDS.PRICE]: productData.price || "",
    [FORM_FIELDS.DISCOUNT_AMOUNT]: productData.discountAmount || "0",
    [FORM_FIELDS.TAGS]: cleanedTags,
    [FORM_FIELDS.COLORS]: cleanedColors,
    [FORM_FIELDS.SIZE]: cleanedSizes,
    [FORM_FIELDS.OUT_OF_STOCK_SIZES]: cleanedOutOfStockSizes,
    [FORM_FIELDS.HEEL_HEIGHT]: productData.heelHeight || "",
    [FORM_FIELDS.OUT_OF_STOCK]: productData.outOfStock || false,
    [FORM_FIELDS.IMAGE]: productData.image || null,
    [FORM_FIELDS.IMAGE2]: productData.image2 || null,
    [FORM_FIELDS.IMAGE3]: productData.image3 || null,
  };
}, [productData]);


  const validationSchema = Yup.object().shape({
    [FORM_FIELDS.NAME]: Yup.string().required("Product name is required!"),
    [FORM_FIELDS.PRICE]: Yup.number()
      .required("Product price is required!")
      .min(1, "Price must be greater than 0!"),
    [FORM_FIELDS.DISCOUNT_AMOUNT]: Yup.mixed()
      .transform((value) => (value === "" || value === undefined ? 0 : value))
      .test(
        "is-number",
        "Discount must be a valid number!",
        (value) => !isNaN(value)
      ),
    [FORM_FIELDS.TAGS]: Yup.string().required("Tags are required!"),
    [FORM_FIELDS.COLORS]: Yup.string().required("Colors are required!"),
    [FORM_FIELDS.SIZE]: Yup.string()
      .required("Size is required!")
      .test("valid-sizes", "Sizes must be valid numbers!", (value) => {
        const sizesArray = value.split(",").map((size) => size.trim());
        return sizesArray.every((size) => !isNaN(size) && parseFloat(size) > 0);
      }),
    [FORM_FIELDS.OUT_OF_STOCK_SIZES]: Yup.string()
      .nullable()
      .test("valid-sizes", "Sizes must be valid numbers!", (value) => {
        if (!value) return true;
        const sizesArray = value.split(",").map((size) => size.trim());
        return sizesArray.every((size) => !isNaN(size) && parseFloat(size) > 0);
      }),
    [FORM_FIELDS.HEEL_HEIGHT]: Yup.number()
      .required("Heel height is required!")
      .min(1, "Heel height must be greater than 0!"),
    [FORM_FIELDS.IMAGE]: Yup.mixed().nullable(),
    [FORM_FIELDS.IMAGE2]: Yup.mixed().nullable(),
    [FORM_FIELDS.IMAGE3]: Yup.mixed().nullable(),
  });

  const onSubmit = (formValues) => {
    const formData = new FormData();
  
    // Split tags and colors back into arrays
    const processedTags = formValues.tags
      .split(",")
      .map((tag) => tag.trim());
  
    const processedColors = formValues.colors
      .split(",")
      .map((color) => color.trim());
  
    const processedSizes = formValues.size
      .split(",")
      .map((size) => size.trim());
  
    const processedOutOfStockSizes = formValues.outOfStockSizes
      .split(",")
      .map((size) => size.trim());
  
    formData.append("name", formValues.name);
    formData.append("price", formValues.price);
    formData.append("discountAmount", formValues.discountAmount);
    formData.append("tags", processedTags);  // Now an array
    formData.append("colors", processedColors);  // Now an array
    formData.append("size", processedSizes);
    formData.append("outOfStockSizes", processedOutOfStockSizes);
    formData.append("heelHeight", formValues.heelHeight);
    formData.append("outOfStock", formValues.outOfStock);
  
    if (formValues[FORM_FIELDS.IMAGE]) formData.append("image", formValues[FORM_FIELDS.IMAGE]);
    if (formValues[FORM_FIELDS.IMAGE2]) formData.append("image2", formValues[FORM_FIELDS.IMAGE2]);
    if (formValues[FORM_FIELDS.IMAGE3]) formData.append("image3", formValues[FORM_FIELDS.IMAGE3]);
  
    // Log formData before sending
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  
    dispatch(editProduct(id, formData))
      .then(() => {
        dispatch(displayNotification({
          text: "Product updated successfully!",
          type: NOTIFICATION_TYPES.SUCCESS,
        }));
      })
      .catch((error) => {
        console.error("Failed to update product:", error);
      })
      .finally(() => {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
        navigate("/admin/admin-panel");
      });
  };
  
  

  if (!productData) return <div></div>;

  return (
    <div className="admin-add-product-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, isValid, setFieldValue }) => {
          const displayedTags = processTags(values.tags, values.discountAmount);

          const discountAmount = values.discountAmount;
          const price = values.price;
          let discountedPrice = price;

          if (discountAmount && price) {
            discountedPrice = price - (price * discountAmount) / 100;
          }

          const formattedDiscountedPrice = new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(discountedPrice);

          return (
            <Form className="admin-add-product-form">
              <Field name={FORM_FIELDS.OUT_OF_STOCK}>
                {({ form, ...formik }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Checkbox
                      checked={values[FORM_FIELDS.OUT_OF_STOCK]}
                      onChange={() =>
                        form.setFieldValue(
                          FORM_FIELDS.OUT_OF_STOCK,
                          !values[FORM_FIELDS.OUT_OF_STOCK]
                        )
                      }
                      sx={{
                        color: "black",
                        "&.Mui-checked": {
                          color: "black",
                        },
                      }}
                    />
                    <label style={{ marginLeft: "8px" }}>Out of stock</label>
                  </div>
                )}
              </Field>

              <div className="admin-form-images-row">
                <div className="admin-form-group-images">
                  <label htmlFor="image">Image 1</label>
                  <label htmlFor="image" className="custom-file-upload">
                    Upload Image 1
                  </label>
                  <input
                    id="image"
                    type="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue(FORM_FIELDS.IMAGE, file);
                      setImage(file ? URL.createObjectURL(file) : null);
                    }}
                  />
                  {image ? (
                    <img
                      className="admin-form-image"
                      src={image}
                      alt="Preview 1"
                    />
                  ) : (
                    productData.image && (
                      <img
                        className="admin-form-image"
                        src={productData.image}
                        alt="Preview 1"
                      />
                    )
                  )}
                </div>

                <div className="admin-form-group-images">
                  <label htmlFor="image2">Image 2</label>
                  <label htmlFor="image2" className="custom-file-upload">
                    Upload Image 2
                  </label>
                  <input
                    id="image2"
                    type="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue(FORM_FIELDS.IMAGE2, file);
                      setImage2(file ? URL.createObjectURL(file) : null);
                    }}
                  />
                  {image2 ? (
                    <img
                      className="admin-form-image"
                      src={image2}
                      alt="Preview 2"
                    />
                  ) : (
                    productData.image2 && (
                      <img
                        className="admin-form-image"
                        src={productData.image2}
                        alt="Preview 2"
                      />
                    )
                  )}
                </div>

                <div className="admin-form-group-images">
                  <label htmlFor="image3">Image 3</label>
                  <label htmlFor="image3" className="custom-file-upload">
                    Upload Image 3
                  </label>
                  <input
                    id="image3"
                    type="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue(FORM_FIELDS.IMAGE3, file);
                      setImage3(file ? URL.createObjectURL(file) : null);
                    }}
                  />
                  {image3 ? (
                    <img
                      className="admin-form-image"
                      src={image3}
                      alt="Preview 3"
                    />
                  ) : (
                    productData.image3 && (
                      <img
                        className="admin-form-image"
                        src={productData.image3}
                        alt="Preview 3"
                      />
                    )
                  )}
                </div>
              </div>

              <div className="admin-form-group">
                <Field name={FORM_FIELDS.NAME}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Product Name"
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
                <Field name={FORM_FIELDS.HEEL_HEIGHT}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Heel Height"
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
              </div>
              <div className="admin-form-group">
                <Field name={FORM_FIELDS.PRICE}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Price"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {discountAmount > 0 && price > 0 && (
                              <span
                                style={{ marginRight: "10px", color: "red" }}
                              >
                                ({formattedDiscountedPrice} ) RSD
                              </span>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
                <Field name={FORM_FIELDS.DISCOUNT_AMOUNT}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Discount Amount (%)"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
              </div>
             <div style={{ position: "relative", width: "100%" }}>
              <Field name={FORM_FIELDS.TAGS}>
                {({ form, ...formik }) => (
                  <>
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Tags (comma-separated)"
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowTagDropdown((prev) => !prev)
                              }
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showTagDropdown && (
                      <CustomDropdown
                      color={true}
                        options={tags.map((tag) => ({
                          value: tag._id,
                          label: tag.name,
                        }))}
                        selectedValues={values[FORM_FIELDS.TAGS]
                          .split(",")
                          .map((tag) => tag.trim())}
                        onChange={(selected) => {
                          setFieldValue(FORM_FIELDS.TAGS, selected.join(", "));
                          setShowTagDropdown(false);
                        }}
                      />
                    )}
                  </>
                )}
              </Field>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
              <Field name={FORM_FIELDS.COLORS}>
                {({ form, ...formik }) => (
                  <>
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Colors (comma-separated)"
                      fullWidth
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowColorDropdown((prev) => !prev)
                              }
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {showColorDropdown && (
                      <CustomDropdown
                        options={colors.map((color) => ({
                          value: color.hex,
                          label: color.name,
                        }))}
                        selectedValues={values[FORM_FIELDS.COLORS]
                          .split(",")
                          .map((color) => color.trim())}
                        onChange={(selected) => {
                          setFieldValue(
                            FORM_FIELDS.COLORS,
                            selected.join(", ")
                          );
                          setShowColorDropdown(false);
                        }}
                      />
                    )}
                  </>
                )}
              </Field>
              </div>
              <Field name={FORM_FIELDS.SIZE}>
                {({ form, ...formik }) => (
                  <InputFieldFormik
                    form={form}
                    {...formik}
                    label="Sizes (comma-separated)"
                    fullWidth
                    size="small"
                  />
                )}
              </Field>

              <Field name={FORM_FIELDS.OUT_OF_STOCK_SIZES}>
                {({ form, ...formik }) => (
                  <InputFieldFormik
                    form={form}
                    {...formik}
                    label="Out of Stock Sizes (comma-separated)"
                    fullWidth
                    size="small"
                    onChange={(e) =>
                      setFieldValue(
                        FORM_FIELDS.OUT_OF_STOCK_SIZES,
                        e.target.value
                      )
                    }
                  />
                )}
              </Field>

              <CustomButton
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isValid}
                className="submit-btn"
                textButton="UPDATE PRODUCT"
                backgroundColor="#007BFF"
                hoverColor="white"
                hoveBgColor="#0056b3"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AdminPanelEditProduct;
