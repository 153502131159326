import "./admin-panel-products.css";
import {
  useMediaQuery,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useGetAllProducts from "../../helpers/hooks/useGetAllProducts";
import {
  setDataState,
  displayNotification,
} from "../../store/reducers/notificationSlice";
import { deleteProduct, saveFeaturedProducts } from "../adminPanel.actions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DATA_STATE, NOTIFICATION_TYPES } from "../../helpers/app.constants";
import socket from "../../helpers/socket";
import SearchInput from "../../components/header/components/SearchInput";
import FeaturedProducts from "../components/FeaturedProducts";
import useGetFeaturedProducts from "../../helpers/hooks/useGetFeaturedProducts";
import ProductRow from "../components/ProductRow";
import { FixedSizeList as List } from "react-window";
import useGetFeaturedCategories from "../../helpers/hooks/useGetFeaturedCategories";
import {
  areArraysEqual,
} from "../components/adminUtils";

const AdminPanelProducts = () => {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery("(max-width: 1400px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useGetAllProducts();
  const MemoizedProductRow = React.memo(ProductRow);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productList, setProductList] = useState(products || []);
  const [searchQuery, setSearchQuery] = useState("");
  //const featuredProducts = useGetFeaturedProducts();
  const featuredCategories = useGetFeaturedCategories();
  const [localFeaturedProducts, setLocalFeaturedProducts] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const {  mappedFeaturedProducts } =
    useGetFeaturedProducts();

  useEffect(() => {
    if (
      products &&
      products.length > 0 &&
      !areArraysEqual(products, productList)
    ) {
      setProductList([...products]); 
    }
  }, [products]); 

  const featuredProductIds = useMemo(() => {
    const ids = new Set();
    Object.values(localFeaturedProducts).forEach((products) => {
      products.forEach((product) => {
        ids.add(product._id);
      });
    });
    return ids;
  }, [localFeaturedProducts]); 

  
  const mergeFeaturedProducts = (fetchedProducts, localFeaturedProducts) => {
    const mergedProducts = { ...localFeaturedProducts };

    Object.keys(fetchedProducts).forEach((category) => {
      const fetchedCategoryProducts = Array.isArray(fetchedProducts[category])
        ? fetchedProducts[category]
        : [];

      const localCategoryProducts = Array.isArray(
        localFeaturedProducts[category]
      )
        ? localFeaturedProducts[category]
        : [];

      mergedProducts[category] = [
        ...localCategoryProducts,
        ...fetchedCategoryProducts.filter(
          (fetchedProduct) =>
            !localCategoryProducts.some(
              (localProduct) => localProduct._id === fetchedProduct._id
            )
        ),
      ];
    });

    return mergedProducts;
  };

  const handleFetchedFeaturedProducts = () => {
    const merged = mergeFeaturedProducts(
      mappedFeaturedProducts,
      localFeaturedProducts
    );
    setLocalFeaturedProducts(merged);
  };

  useEffect(() => {
    if (Object.keys(mappedFeaturedProducts).length > 0) {
      handleFetchedFeaturedProducts();
    }
  }, [mappedFeaturedProducts]);

  useEffect(() => {
    socket.on("productDeleted", ({ productId }) => {
      setProductList((prevProducts) =>
        prevProducts.filter((product) => product._id !== productId)
      );
    });

    return () => {
      socket.off("productDeleted");
    };
  }, [dispatch]);

  const openDeleteModal = (product) => {
    setSelectedProduct(product);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedProduct(null);
  };

  const openCategoryModal = (product) => {
    console.log("Opening category modal for product MODAL:", product);
    setSelectedProduct(product); 
    setCategoryModalOpen(true); 
  };

  const closeCategoryModal = () => {
    setCategoryModalOpen(false);
    setSelectedProduct(null);
  };

  const handleProductClick = useCallback(
    (productId) => {
      navigate(`/admin/admin-panel/product-edit/${productId}`);
    },
    [navigate]
  );

  const handleDeleteProduct = async (productId) => {
    try {
      dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
      await dispatch(deleteProduct(productId));
      setDeleteModalOpen(false);

      setProductList((prevProducts) =>
        prevProducts.filter((product) => product._id !== productId)
      );

      const notificationPayload = {
        text: "Product deleted!",
        type: NOTIFICATION_TYPES.SUCCESS,
      };
      dispatch(displayNotification(notificationPayload));
    } catch (error) {
      const notificationPayload = {
        text: "Error occurred!",
        type: NOTIFICATION_TYPES.ERROR,
      };
      dispatch(displayNotification(notificationPayload));
    } finally {
      dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    }
  };

  const handleCategorySelect = (event) => {
    const selectedValue = event.target.value;
    console.log("Category Selected:", selectedValue); 
    setSelectedCategory(selectedValue); 
  };

  const handleProductToggle = useCallback(
    (product) => {
      console.log(`PRODUCT TOGGLE: PRODUCT: `, product);
  
      setLocalFeaturedProducts((prevState) => {
        const categoryContainingProduct = Object.keys(prevState).find(
          (category) => prevState[category].some((p) => p._id === product._id)
        );
  
        if (categoryContainingProduct) {
          const updatedCategoryProducts = prevState[
            categoryContainingProduct
          ].filter((p) => p._id !== product._id);
  
          console.log(
            `Removing product ${product.name} from category ${categoryContainingProduct}`
          );
  
          const newState = {
            ...prevState,
            [categoryContainingProduct]: updatedCategoryProducts,
          };
  
          console.log("Updated state after removing:", newState);
          dispatch(saveFeaturedProducts(newState));
          return newState;
        } else {
          setSelectedProduct(product); 
          openCategoryModal(product); 
          return prevState; 
        }
      });
    },
    [dispatch, openCategoryModal] 
  );
  
  const handleAddProductToCategory = useCallback(() => {
    console.log("Attempting to add product to category after selection");
    console.log("Selected Product:", selectedProduct);
    console.log("Selected Category:", selectedCategory);
  
    if (!selectedCategory || !selectedProduct?._id) {
      console.error("Product or Category is not selected properly");
      alert("Please select a category and a product.");
      return;
    }
  
    setLocalFeaturedProducts((prevState) => {
      const categoryProducts = prevState[selectedCategory] || [];
  
      if (categoryProducts.length >= 8) {
        alert(
          `You can only have a maximum of 8 featured products in the "${selectedCategory}" category.`
        );
        return prevState;
      }
  
      const updatedCategoryProducts = [...categoryProducts, selectedProduct];
      const newState = {
        ...prevState,
        [selectedCategory]: updatedCategoryProducts,
      };
  
      console.log("New state after adding product to category:", newState);
      dispatch(saveFeaturedProducts(newState)); 
  
      closeCategoryModal(); 
      return newState; 
    });
  }, [dispatch, selectedCategory, selectedProduct, closeCategoryModal]);
  

  // // Track changes in selectedProduct and selectedCategory using useEffect
  // useEffect(() => {
  //   console.log("Selected Product Updated:", selectedProduct);
  // }, [selectedProduct]);

  // useEffect(() => {
  //   console.log("Selected Category Updated:", selectedCategory);
  // }, [selectedCategory]);

  // // Track updates to localFeaturedProducts
  // useEffect(() => {
  //   console.log("Local Featured Products Updated:", localFeaturedProducts);
  // }, [localFeaturedProducts]);

  const filteredProducts = useMemo(() => {
    if (searchQuery.trim() === "") {
      return productList;
    }

    return productList.filter((product) => {
      const query = searchQuery.toLowerCase();
      return (
        product.name.toLowerCase().includes(query) ||
        product.size.join(", ").toLowerCase().includes(query) ||
        product.tags?.some((tag) => tag.toLowerCase().includes(query)) ||
        product.color?.some((col) => col.toLowerCase().includes(query)) ||
        product.price.toString().includes(query) ||
        product.heelHeight.toString().includes(query) ||
        (product.discountAmount &&
          product.discountAmount.toString().includes(query))
      );
    });
  }, [searchQuery, productList]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim() === "") {
      return;
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const ProductRowRenderer = ({ index, style }) => {
    const product = filteredProducts[index];
    const isFeatured = featuredProductIds.has(product._id); 

    return (
      <MemoizedProductRow
        style={style}
        product={product}
        isFeatured={isFeatured}
        isScreenSmall={isScreenSmall}
        handleProductToggle={handleProductToggle}
        handleProductClick={handleProductClick}
        openDeleteModal={openDeleteModal}
      />
    );
  };
  

  if (!products || products.length === 0) {
    return <div></div>;
  }

  return (
    <Box sx={{ margin: "0 1vw", minHeight: "100vh" }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "0.5fr 0.8fr 2fr 1.2fr 1.2fr 1fr 0.8fr 1fr",
          gap: 2,
          borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
          fontSize: isScreenSmall ? "0.8em" : "0.95em",
          textAlign: "left",
          height: "64px",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Typography variant="body1"></Typography>
        <Typography variant="body1" sx={{ textAlign: "left", width: "100%" }}>
          IMAGE
        </Typography>
        <Box sx={{ width: "100%" }}>
          <SearchInput
            placeholder="Name, Tag, Color, Heel Height, Size"
            value={searchQuery}
            onChange={handleSearchChange}
            onSubmit={handleSearchSubmit}
            onClear={handleClearSearch}
          />
        </Box>
        <Typography variant="body1">SIZES</Typography>
        <Typography variant="body1">HEEL</Typography>
        <Typography variant="body1">PRICE</Typography>
        <Typography variant="body1">DISCOUNT</Typography>
        <Typography
          variant="body1"
          sx={{
            width: "100%",
            textAlign: "right",
            mr: 1,
          }}
        >
          ACTIONS
        </Typography>
      </Box>
      <FeaturedProducts
        handleProductToggle={handleProductToggle}
        featuredProducts={localFeaturedProducts}
        featuredCategories={featuredCategories}
        handleProductClick={handleProductClick}
        openDeleteModal={openDeleteModal}
        openCategoryModal={openCategoryModal}
        isScreenSmall={isScreenSmall}
      />
      <List
        height={600}
        itemCount={filteredProducts.length}
        itemSize={70}
        width="100%"
      >
        {ProductRowRenderer}
      </List>

      {/* Delete Product Modal */}
      <Dialog open={deleteModalOpen} onClose={closeDeleteModal}>
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete{" "}
            <strong>{selectedProduct?.name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="customOpposite">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteProduct(selectedProduct?._id)}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Category Selection Modal */}
      <Dialog open={categoryModalOpen} onClose={closeCategoryModal}>
        <DialogTitle>Select Category</DialogTitle>
        <DialogContent>
          <Typography>Select a category to add this product to:</Typography>
          <RadioGroup value={selectedCategory} onChange={handleCategorySelect}>
            {featuredCategories.map((category) => (
              <FormControlLabel
                key={category._id}
                value={category.name} 
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "black", 
                      },
                    }}
                  />
                }
                label={category.name}
              />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCategoryModal} color="customRed">
            Cancel
          </Button>
          <Button onClick={handleAddProductToCategory} color="customOpposite">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminPanelProducts;
