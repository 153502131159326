import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomField from "../../components/other/CustomField";
import { useDispatch } from "react-redux";
import { addTag, deleteTag, editTag, getTags } from "../adminPanel.actions";

const predefinedTags = ["sale", "new", "woman", "man", "kids"];

const AdminTagManager = () => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [editTagId, setEditTagId] = useState(null);
  const [editTagName, setEditTagName] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(null);
  const [selectedTagName, setSelectedTagName] = useState("");

  useEffect(() => {
    dispatch(getTags())
      .then((fetchedTags) => setTags(fetchedTags))
      .catch((error) => console.error("Error fetching tags:", error));
  }, [dispatch]);

  const handleAddTag = () => {
    if (newTag.trim() !== "") {
      addTag(newTag)(dispatch)
        .then((response) => {
          const newTagFromServer = response;
          setTags((prevTags) => [...prevTags, newTagFromServer]);
        })
        .catch((error) => console.error("Error adding tag:", error));
      setNewTag("");
    }
  };

  const openDeleteModal = (id, name) => {
    setSelectedTagId(id);
    setSelectedTagName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTagId(null);
    setSelectedTagName("");
  };

  const confirmDelete = () => {
    const lowercaseName = selectedTagName.toLowerCase(); // Ensure case-insensitive comparison
    const predefinedLowercaseTags = predefinedTags.map((tag) =>
      tag.toLowerCase()
    ); // Convert predefined tags to lowercase

    if (!predefinedLowercaseTags.includes(lowercaseName)) {
      deleteTag(selectedTagId)(dispatch)
        .then(() => setTags(tags.filter((tag) => tag._id !== selectedTagId)))
        .catch((error) => console.error("Error deleting tag:", error));
    } else {
      alert(`The tag "${selectedTagName}" cannot be deleted.`);
    }
    closeModal(); // Close the modal after deletion
  };

  const handleEditTag = (id, name) => {
    if (!predefinedTags.includes(name.toLowerCase())) {
      setEditTagId(id);
      setEditTagName(name);
    } else {
      alert("This tag cannot be edited.");
    }
  };

  const handleSaveEditTag = () => {
    if (editTagId && editTagName.trim() !== "") {
      editTag(
        editTagId,
        editTagName
      )(dispatch)
        .then(() => {
          setTags(
            tags.map((tag) =>
              tag._id === editTagId ? { ...tag, name: editTagName } : tag
            )
          );
        })
        .catch((error) => console.error("Error editing tag:", error));
      setEditTagId(null);
      setEditTagName("");
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Manage Tags</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <CustomField
                label="Add New Tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                width="300px"
                size="small"
                regular={true}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTag}
              >
                Add Tag
              </Button>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {tags.map((tag, index) => (
                <Box
                  key={tag._id || index} // Fallback to 'index' if '_id' is not available
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "8px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {editTagId === tag._id ? (
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <CustomField
                        label="Edit Tag"
                        value={editTagName}
                        onChange={(e) => setEditTagName(e.target.value)}
                        width="300px"
                        size="small"
                        regular={true}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveEditTag}
                      >
                        Save
                      </Button>
                    </Box>
                  ) : (
                    <>
                      <Typography>{tag.name}</Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          onClick={() => handleEditTag(tag._id, tag.name)}
                          sx={{ color: "blue" }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => openDeleteModal(tag._id, tag.name)}
                          sx={{ color: "red" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the tag
            <strong> {selectedTagName}</strong>?
            <br />
            <br />
            Deleting this tag will affect any associated products and they will
            no longer be displayed in filtering options because tag wont exist.
            <br />
            <br />
            Additionaly if <strong>{selectedTagName}</strong> is set as
            <u> Featured Products Category </u>
            it will be deleted too.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="customRed" variant="outlined">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminTagManager;
