import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Badge,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
export const AdminPanelTabDrawer = ({
  tabs,
  activeTab,
  handleSettingActiveTab,
  newOrdersCount,
  handleResetOrdersCount,
  handleLogout,
  theme,
}) => (
  <Drawer
    variant="permanent"
    sx={{
      width: 240,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: {
        width: 240,
        boxSizing: "border-box",
        backgroundColor: theme.palette.background.paper,
      },
    }}
  >
    <Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">Dashboard</Typography>
      </Box>
      <Box
        onClick={() => {
          handleSettingActiveTab("ORDERS");
          if (newOrdersCount > 0) {
            handleResetOrdersCount();
          }
        }}
      >
        <Badge
          badgeContent={newOrdersCount > 0 ? newOrdersCount : null}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: theme.palette.blue.main,
              color: "white",
            },
          }}
        >
          <NotificationsIcon sx={{ cursor: "pointer" }} />
        </Badge>
      </Box>
    </Toolbar>
    <Divider />
    <List>
      {tabs.map(({ key, label }) => (
        <ListItem
          button
          key={key}
          selected={activeTab.key === key}
          onClick={() => {
            handleSettingActiveTab(key);
            if (key === "ORDERS" && newOrdersCount > 0) {
              handleResetOrdersCount();
            }
          }}
          sx={{
            color: activeTab.key === key ? "#007BFF" : "inherit",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
            "&.Mui-selected": {
              backgroundColor: theme.palette.action.selected,
            },
          }}
        >
          <ListItemText primary={label} />
        </ListItem>
      ))}
    </List>
    <Box sx={{ flexGrow: 1 }} />
    <List>
      <ListItem
        button
        onClick={handleLogout}
        sx={{
          textAlign: "center",
          color: "red",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  </Drawer>
);
