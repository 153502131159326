const calculateModel97 = (number) => {
  const baseNumber = number.replace(/[^0-9]/g, "") + "00"; // Ensure only numeric values
  const checksum = (98 - (parseInt(baseNumber, 10) % 97))
    .toString()
    .padStart(2, "0");
  return `97${checksum}${baseNumber.slice(0, -2)}`;
};

export const generateQRData = ({
  bankAccount,
  amount,
  reference, 
  paymentCode,
  companyName,
}) => {
  const localBankAccount = bankAccount.replace(/[^0-9]/g, "").padStart(18, "0");

  const formattedAmount = amount?.replace(".", ",") || "";
  const numericReference = reference.replace(/[^0-9]/g, ""); 
  const model97Reference = calculateModel97(numericReference); 

  //console.log("ORDER ID", reference)
  //console.log("numericReference", numericReference)
  //console.log("FINAL REFERENCE", model97Reference)

  const validPaymentCode = paymentCode?.trim() || "189"; 

  let qrData =
    `K:PR|` + 
    `V:01|` + 
    `C:1|` + 
    `R:${localBankAccount}|` +  
    `N:${companyName}|` + 
    `I:RSD${formattedAmount}|` + 
    `RO:${model97Reference}|` +  
    `SF:${validPaymentCode}`;

  qrData = qrData.replace(/\|[A-Z]+:\|/g, ""); 

  return qrData.trim(); 
};

  export const generateOrderID = () => {
    return Math.floor(1000 + Math.random() * 9000).toString();
  };
  