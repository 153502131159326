import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newOrdersCount: 0,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setNewOrdersCount(state, action) {
      state.newOrdersCount = action.payload;
    },

    resetNewOrders(state) {
      state.newOrdersCount = 0;
    },
  },
});

export const { incrementNewOrder, setNewOrdersCount, resetNewOrders } =
  adminSlice.actions;

export const selectNewOrdersCount = (state) => state.admin.newOrdersCount;

export default adminSlice.reducer;
