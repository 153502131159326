import { useState, useEffect } from "react";
import axios from "axios";
import { DATA_STATE, NOTIFICATION_TYPES, SERVER_URL } from "../app.constants";
import useGetAllProducts from "./useGetAllProducts";
import { displayNotification } from "../../store/reducers/notificationSlice";
import { useDispatch } from "react-redux";

const useGetFeaturedProducts = () => {
  const dispatch = useDispatch();
  const [featuredProducts, setFeaturedProducts] = useState({
    rawFeaturedProducts: {},  // To store product IDs by category
    mappedFeaturedProducts: {},  // To store actual product details by category
  });
  const allProducts = useGetAllProducts(); // Fetches all product details

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      if (allProducts.length === 0) {
        return;
      }

      try {
        const response = await axios.get(`${SERVER_URL}product/getfeaturedproducts`);

        if (response.status === 200 && response.data?.featuredProducts) {
          const featured = response.data.featuredProducts;

          const rawFeaturedProducts = { ...featured };

          const mappedFeaturedProducts = Object.keys(featured).reduce((acc, categoryName) => {
            const productsInCategory = featured[categoryName].map((productId) => 
              allProducts.find((product) => product._id === productId)
            );
            acc[categoryName] = productsInCategory.filter(Boolean); // Filter out null if product doesn't exist
            return acc;
          }, {});

          setFeaturedProducts({
            rawFeaturedProducts,
            mappedFeaturedProducts,
          });
        } else {
          setFeaturedProducts({
            rawFeaturedProducts: {},
            mappedFeaturedProducts: {},
          });
        }
      } catch (error) {
        console.error("Error fetching featured products:", error);
        const notificationPayload = {
          text: "Error fetching featured products!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload));
      }
    };

    fetchFeaturedProducts();
  }, [allProducts, dispatch]);

  return featuredProducts;
};




export default useGetFeaturedProducts;
