import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import SectionComponent from "./SectionComponent";
import { selectActiveLanguage, selectScreenSize } from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import "./products-mainpage.css";
import { useTheme } from "@emotion/react";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import useGetFeaturedProducts from "../../../helpers/hooks/useGetFeaturedProducts";
import { clearFilters } from "../../../store/reducers/filterSlice";
import useGetFeaturedCategories from "../../../helpers/hooks/useGetFeaturedCategories";
import { useState, useEffect } from "react";

function ProductsMainpage() {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMedium = useMediaQuery("(max-width: 1500px)");
  const { mappedFeaturedProducts } = useGetFeaturedProducts();
  const featuredCategories = useGetFeaturedCategories(); 

  const [isAnimationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    if (Object.keys(mappedFeaturedProducts).length > 0 && featuredCategories.length > 0) {
      setAnimationTriggered(true);
    }
  }, [mappedFeaturedProducts, featuredCategories]);

  const categoryOrder = ['bestseller', 'women', 'boots', 'man', 'kids'];

  const sortedCategories = [...featuredCategories].sort((a, b) => {
    const aPriority = categoryOrder.includes(a.name) ? categoryOrder.indexOf(a.name) : Infinity;
    const bPriority = categoryOrder.includes(b.name) ? categoryOrder.indexOf(b.name) : Infinity;
  
    return aPriority - bPriority;
  });

  const getCategoryTitle = (categoryName) => {
    return translatedLanguages.filters[categoryName] || categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
  };

  const handleViewAllProducts = () => {
    dispatch(clearFilters());
    navigate("/shop");
  };

  return (
    <div
      className={`products-mainpage-container ${isAnimationTriggered ? 'slide-in' : ''}`}
      style={{
        marginLeft: isSmallScreen ? "" : "50px",
        width: isSmallScreen ? "100%" : "calc(100% - 50px)",
        minHeight: isSmallScreen ? "65vh" : "85vh",
        height: "fit-content",
        padding: isSmallScreen ? "10vh 0vh" : "5vh 0",
        display: "flex",
        borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
        flexDirection: "column",
      }}
    >
      {sortedCategories.length > 0 ? (
        sortedCategories.map((category) => {
          const productsInCategory = mappedFeaturedProducts[category.name] || [];

          return (
            <div 
              key={category._id} 
              className={`products-section-container ${isAnimationTriggered ? 'products-slide-in' : ''}`}
            >
              <SectionComponent
                title={getCategoryTitle(category.name)}
                filteredProducts={productsInCategory}
                isSmallScreen={isSmallScreen}
                isMedium={isMedium}
                navigate={navigate}
                translatedLanguages={translatedLanguages}
                filterTag={category.name}
              />
            </div>
          );
        })
      ) : (
        <div>No categories available</div>
      )}

      <div className="products-allproducts-link" onClick={handleViewAllProducts}>
        {translatedLanguages.viewAllProducts || "View All Products"}
        <ClearAllIcon sx={{ stroke: "white", strokeWidth: 0.5 }} />
      </div>
    </div>
  );
}

export default ProductsMainpage;
