import React, { useEffect } from "react";
import "./homepage.css";
import ImageSlider from "./mainpage/ImageSlider";
import ProductsMainpage from "./mainpage/ProductsMainpage";
import AboutUs from "./mainpage/AboutUs";
import { useLocation } from "react-router-dom";

function HomePage() {
	const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

	return (
		<div className="homepage-container">
			<ImageSlider />
			<ProductsMainpage />
			<AboutUs />
		</div>
	);
}

export default HomePage;
