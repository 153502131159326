import React from "react";
import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";

const ProductRow = React.memo(
  ({
    product,
    isFeatured,
    isScreenSmall,
    handleProductToggle,
    handleProductClick,
    openDeleteModal,
    style,
  }) => {
    const theme = useTheme();

    return (
      <Box
        style={style}
        key={product._id}
        sx={{
          display: "grid",
          gridTemplateColumns: "0.5fr 0.8fr 2fr 1.2fr 1.2fr 1fr 0.8fr 1fr",
          gap: 2,
          alignItems: "center",
          fontSize: isScreenSmall ? "0.8em" : "0.95em",
          textAlign: "left",
          height: "64px",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tooltip
          title={isFeatured ? "Remove from featured" : "Add to featured"}
        >
          <Checkbox
            sx={{
              color: theme.palette.customOppositeLighter.main,
              "&.Mui-checked": {
                color: theme.palette.customOpposite.main,
              },
            }}
            checked={isFeatured}
            onChange={() => handleProductToggle(product)}
          />
        </Tooltip>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <img
            src={product.image}
            alt={product.name}
            style={{ width: "50px", height: "50px", objectFit: "cover" }}
            loading="lazy"
          />
        </Box>
        <Typography sx={{ overflow: "hidden" }}>{product.name}</Typography>
        <Typography
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {product.size.join(", ")}
        </Typography>
        <Typography sx={{ overflow: "hidden", textAlign: "center" }}>
          {product.heelHeight}
        </Typography>
        <Typography sx={{ overflow: "hidden", textAlign: "center" }}>
          {product.discountAmount > 0 ? (
            <span style={{ color: "red" }}>
              {(
                product.price -
                (product.price * product.discountAmount) / 100
              ).toLocaleString("de-DE")}
              ,00
            </span>
          ) : (
            product.price.toLocaleString("de-DE") + ",00"
          )}
        </Typography>
        <Typography sx={{ overflow: "hidden", textAlign: "center" }}>
          {product.discountAmount ? `${product.discountAmount}%` : "/"}
        </Typography>

        <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
          <EditIcon
            sx={{
              cursor: "pointer",
              marginRight: "10px",
              fontSize: "1.5em",
            }}
            onClick={() => handleProductClick(product._id)}
          />
          <DeleteIcon
            sx={{
              cursor: "pointer",
              marginRight: "10px",
              fontSize: "1.5em",
            }}
            onClick={() => openDeleteModal(product)}
          />
        </Box>
      </Box>
    );
  }
);

export default ProductRow;
