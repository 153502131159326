import { useSelector } from "react-redux";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../store/reducers/userSlice";
import "./about-us-page.css";
import { useTheme } from "@emotion/react";
import { translations } from "../../helpers/translations.js";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import Bredcrumbs from "../other/Bredcrumbs.js";
import useSlideInAnimation from "../../helpers/hooks/useSlideInAnimation.js";
import { useMediaQuery } from "@mui/material";
import QAsection from "./abouUsPage/QAsection.js";
import { useLocation } from "react-router-dom";

function AboutUsPage() {
	const theme = useTheme();
	const location = useLocation();
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const isMedium = useMediaQuery("(max-width: 1000px)");
	const [isHeadingVisible, headingRef] = useSlideInAnimation(
		0.4,
		"0px 0px -100px 0px",
	);
	const [isFirstSectionVisible, firstSectionRef] = useSlideInAnimation(
		0.4,
		"0px 0px -150px 0px",
	);
	const [isSecondSectionVisible, secondSectionRef] = useSlideInAnimation(
		0.6,
		"0px 0px -100px 0px",
	);

	useEffect(() => {
		if (!location.hash) {
			window.scrollTo(0, 0);
		}
	}, [location]);

	return (
		<div
			className="about-us-page-container"
			style={{
				minHeight: isSmallScreen ? "95vh" : "100vh",
				height: "fit-content",
				width: isSmallScreen ? "100%" : "calc(100% - 50px)",
				marginLeft: isSmallScreen ? "" : "50px",
			}}
		>
			<Bredcrumbs pageName={translatedLanguages.aboutUs} pageLink="/about" />
			<div
				className="about-us-image-container"
				style={{
					marginTop: isSmallScreen ? "2vh" : "2vh",
					height: isSmallScreen ? "65vh" : "70vh",
				}}
			>
				<span
					ref={headingRef}
					className={`${isHeadingVisible ? "slide-in" : ""}`}
					style={{
						color: theme.palette.primary.main1,
						fontSize: isSmallScreen ? "3em" : "5em",
					}}
				>
					{translatedLanguages.aboutUs.toUpperCase()}
				</span>
			</div>
			<div
				ref={firstSectionRef}
				className={`about-us-section-container ${
					isFirstSectionVisible ? "slide-in" : ""
				}`}
				style={{
					height: isSmallScreen || isMedium ? "fit-content" : "50vh",
					flexDirection: isSmallScreen || isMedium ? "column" : "row",
				}}
			>
				<div
					className="about-us-section-image about-us-section-image1"
					style={{
						width: isSmallScreen || isMedium ? "100% " : "27%",
						minHeight: isSmallScreen || isMedium ? "300px " : "",
						backgroundPosition:
							isSmallScreen || isMedium ? "bottom " : "center",
					}}
				></div>
				<div
					className="about-us-section-content-container"
					style={{
						width: isSmallScreen || isMedium ?  "100%" : "70%",
						marginLeft: isSmallScreen || isMedium ? "" : "5vh",
					}}
				>
					<h2
						style={{
							textAlign: isSmallScreen || isMedium ? "center " : "left",
						}}
					>
						Soul and Sole
					</h2>
					<div>
						{translatedLanguages.content
							.split("\n\n")
							.slice(0, 2)
							.map((paragraph, index, array) => (
								<p key={index}>{paragraph}</p>
							))}
					</div>
				</div>
			</div>

			<div
				ref={secondSectionRef}
				className={`about-us-section-container about-us-section-container-left ${
					isSecondSectionVisible ? "slide-in" : ""
				}`}
				style={{
					height: isSmallScreen || isMedium ? "fit-content" : "50vh",
					flexDirection: isSmallScreen || isMedium ? "column" : "row-reverse",
				}}
			>
				<div
					className="about-us-section-image about-us-section-image2"
					style={{
						width: isSmallScreen || isMedium ? "100% " : "27%",
						height: isSmallScreen || isMedium ? "300px " : "400px",
						backgroundPosition:
							isSmallScreen || isMedium ? "bottom " : "center",
					}}
				></div>
				<div
					className="about-us-section-content-container"
					style={{
						width: isSmallScreen || isMedium ?  "100%" : "70%",
						margin: isSmallScreen || isMedium ? "" : "0 5vh 0 0",
					}}
				>
					{/* <h2
						style={{
							textAlign: isSmallScreen || isMedium ? "center " : "left",
						}}
					>
					
					</h2> */}
					<div>
						{translatedLanguages.content
							.split("\n\n")
							.slice(2, 4)
							.map((paragraph, index, array) => (
								<p key={index}>{paragraph}</p>
							))}
					</div>
				</div>
			</div>
			<QAsection />
		</div>
	);
}

export default AboutUsPage;
