const CustomerDetails = ({ customerData }) => (
    <div className="order-details">
      {customerData.map((item, index) => (
        <div className="order-detail-row" key={index}>
          <div className="order-detail-label">{item.label}:</div>
          <div className="order-detail-value">{item.value}</div>
        </div>
      ))}
    </div>
  );
  
  export default CustomerDetails;