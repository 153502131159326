import "./shoppage.css";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import React, { useEffect, useMemo, useState } from "react";
import { translations } from "../../helpers/translations.js";
import Bredcrumbs from "../other/Bredcrumbs";
import ProductContainer from "../other/ProductContainer";
import { useSelector } from "react-redux";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../store/reducers/userSlice";
import {
  selectFilteredProducts,
} from "../../store/reducers/filterSlice";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { selectAllProducts } from "../../store/reducers/productSlice.js";

function ShopPage() {
  const location = useLocation();
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const loading = useSelector((state) => state.filter.loading);
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const queryParams = new URLSearchParams(location.search);
  const filterTag = queryParams.get("filter");

  const products = useSelector(selectAllProducts);
  const { products: filteredProducts, noProductsFound } = useSelector(
    selectFilteredProducts
  );
  const displayProducts = useMemo(() => {
    const sortedProducts = [...(filteredProducts.length > 0 || noProductsFound
      ? filteredProducts
      : products)].sort((a, b) => a.name.localeCompare(b.name));
  
    return sortedProducts;
  }, [filteredProducts, noProductsFound, products]);

  const noFiltersApplied = !filterTag && filteredProducts.length === 0;
  const noProductsAvailable = noProductsFound;

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 24;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filteredProducts]);
  
  const currentProducts = useMemo(() => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    return displayProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  }, [displayProducts, currentPage]);

  const totalPages = Math.ceil(displayProducts.length / productsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };


  useEffect(() => {
    setCurrentPage(1);
  }, [displayProducts]);

  const iconStyles = {
    cursor: "pointer",
  };
  return (
    <div
      className="shoppage-container"
      style={{
        minHeight: isSmallScreen ? "65vh" : "100vh",
        width: isSmallScreen ? "100%" : "calc(100% - 60px)",
        marginLeft: isSmallScreen ? "" : "50px",
      }}
    >
      <div className="shoppage-inner-container">
        <Bredcrumbs
          pageName={translatedLanguages.shop}
          pageLink="/shop"
          isShopPage={true}
          filter={filterTag}
        />
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : noProductsAvailable ? (
          <div className="spinner-container">
            <p>{translatedLanguages.noProductsFiltered || "No products matched applied filters."}</p>
          </div>
        ) : (
          <div
            className="shoppage-filtered-container"
            style={{
              display: isSmallScreen ? "flex" : "grid",
              height: noFiltersApplied ? "100%" : "fit-content",
              flexDirection: isSmallScreen ? "column" : "row",
              paddingBottom: "8vh",
            }}
          >
            {currentProducts.map((product) => (
              <ProductContainer
                className="product-container"
                key={product._id}
                product={product}
              />
            ))}
          </div>
        )}
      </div>
      <div
        className="pagination-controls"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NavigateNextIcon
          onClick={handlePreviousPage}
          sx={{
            ...iconStyles,
            transform: "rotate(180deg)",
            marginRight: "10px",
          }}
          disabled={currentPage === 1}
          color={currentPage === 1 ? "fifth" : "customOpposite"}
        />
        <span>{`${currentPage} ${translatedLanguages.from} ${totalPages}`}</span>
        <NavigateNextIcon
          onClick={handleNextPage}
          sx={{
            ...iconStyles,
            marginLeft: "10px",
          }}
          disabled={currentPage === totalPages}
          color={currentPage === totalPages ? "fifth" : "customOpposite"}
        />
      </div>
    </div>
  );
  
}

export default ShopPage;
