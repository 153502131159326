import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setAdminStatus } from "../../store/reducers/userSlice";
import {
  displayNotification,
  setDataState,
} from "../../store/reducers/notificationSlice";
import "./admin-login.css";
import {
  DATA_STATE,
  NOTIFICATION_TYPES,
  SERVER_URL,
} from "../../helpers/app.constants";
import CustomButton from "../../components/other/CustomButton";
import CustomField from "../../components/other/CustomField";
import CloseIcon from "@mui/icons-material/Close";

const AdminLogin = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, { setErrors }) => {
    try {
      dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
      const response = await axios.post(
        `${SERVER_URL}admin/loginadmin`,
        values
      );
      const token = response.data.token;

      dispatch(setAdminStatus({ isAdmin: true, token }));

      const userResponse = await axios.post(
        `${SERVER_URL}admin/getadminuserdata`,
        { token }
      );

      const userData = userResponse.data;
      if (userData.isAdmin !== undefined && userData.token) {
        dispatch(
          setAdminStatus({ isAdmin: userData.isAdmin, token: userData.token })
        );
      }

      dispatch(
        displayNotification({
          text: "Admin login successful!",
          type: NOTIFICATION_TYPES.SUCCESS,
        })
      );

      dispatch(setDataState(DATA_STATE.DATA_STATE_OK));

      navigate("/admin/admin-panel");
    } catch (error) {
      if (error.response && error.response.data.error === "Admin not found") {
        setErrors({ email: "Admin not found" });
      } else if (
        error.response &&
        error.response.data.error === "Invalid password"
      ) {
        setErrors({ password: "Incorrect password" });
      } else {
        dispatch(
          displayNotification({
            text: "Server error!",
            type: NOTIFICATION_TYPES.ERROR,
          })
        );
      }
      dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Grid item xs={12} sm={8} md={4}>
        <Card
          style={{
            border: `1px solid ${theme.palette.customOppositeLighter.main}`,
            borderRadius: "10px",
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => navigate("/")}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <CardContent>
            <Typography variant="h6" align="center" gutterBottom>
              ADMIN LOGIN
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <Form onSubmit={handleSubmit} className="login-form">
                  <CustomField
                    fullWidth
                    margin="30px 0"
                    id="email"
                    name="email"
                    label="Email"
                    password={false}
                    width="100%"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                  />
                  <CustomField
                    fullWidth
                    margin="30px 0 0 0"
                    id="password"
                    name="password"
                    label="Password"
                    password={true}
                    width="100%"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                  />
                  <CustomButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={!values.email || !values.password}
                    margin="40px 0 0 0"
                    textButton="LOGIN"
                    width="100%"
                    backgroundColor="#007BFF"
                    hoveBgColor="#0056b3"
                    isAdmin = {true}
                  />
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AdminLogin;
