import "./cart-step.css";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { translations } from "../../../helpers/translations";
import { Tooltip, Divider, useMediaQuery, Modal } from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  addItemToCart,
  clearCart,
  removeItemsFromCart,
  selectItems,
  selectTotalCartPrice,
} from "../../../store/reducers/cartSlice";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../../store/reducers/userSlice";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import CustomButton from "../../other/CustomButton";
import { applyDiscount } from "../../../adminPanel/components/adminPanelOrderDetails/utils";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

const TIMEOUT_DURATION = 1800000;

function CartStep({ setCurrentStep }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector(selectItems);
  const totalPrice = useSelector(selectTotalCartPrice);
  const cartEmpty = items.length === 0;
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const isMedium = useMediaQuery("(max-width: 1000px)");

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTimeoutModal(true);
      dispatch(clearCart());
    }, TIMEOUT_DURATION);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]);

  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    navigate("/shop");
  };

  const headers = [
    { key: "number", label: translatedLanguages.number },
    { key: "image", label: translatedLanguages.image },
    { key: "name", label: translatedLanguages.name },
    { key: "size", label: translatedLanguages.size },
    { key: "price", label: translatedLanguages.price },
    { key: "quantity", label: translatedLanguages.quantity },
    { key: "actions", label: translatedLanguages.actions },
  ];

  const addToCartHandler = (item) => {
    dispatch(addItemToCart({ product: item.product, size: item.size }));
  };

  const removeFromCartHandler = (item) => {
    dispatch(removeItemsFromCart({ id: item.product._id, size: item.size }));
  };

  return (
    <div
      className="cart-step-container"
      style={{
        minHeight: isSmallScreen ? "95vh" : "80vh",
      }}
    >
      {cartEmpty ? (
        <div
          style={{ minHeight: isSmallScreen ? "59vh" : "75vh" }}
          className="cart-step-empty"
        >
          <div className="cart-step-empty-inner">
            <ShoppingBagOutlinedIcon
              sx={{ fontSize: isSmallScreen ? "3rem" : "5rem" }}
            />
            <p style={{ marginTop: "2vh" }}>{translatedLanguages.cartEmpty}</p>

            <CustomButton
              textButton={
                translatedLanguages.continueShopping || "Continue Shopping"
              }
              onClick={() => navigate("/shop")}
              endIcon={<ShoppingCartCheckoutIcon />}
              width="100%"
              display="flex"
              justifyContent="center"
            />
          </div>
        </div>
      ) : (
        <div
          className="cart-step-products-container"
          style={{
            fontSize: isSmallScreen ? "0.9em" : "1em",
            minHeight: isSmallScreen ? "59vh" : isMedium ? "72vh" : "75vh",
          }}
        >
          <div style={{ padding: isSmallScreen ? "7%" : "5%" }}>
            <div className="cart-step-header">
              {headers.map((header) => (
                <div className="cart-step-column" key={header.key}>
                  <p className="cart-step-column-h">{header.label}</p>
                </div>
              ))}
            </div>

            <Divider />
            <div className="cart-step-items">
              {items.map((item, index) => {
                const price = item.product.price || 0;
                const discount = item.product.discountAmount || 0;
                const [discountedPrice] = applyDiscount(price, discount);

                return (
                  <div
                    className="cart-step-item"
                    key={`${item.product._id}-${item.size}`}
                  >
                    <div className="cart-step-column">{index + 1}</div>
                    <div className="cart-step-column">
                      <img
                        src={item.product.image}
                        alt={item.product.name}
                        className="cart-step-item-image"
                      />
                    </div>
                    <div className="cart-step-column">{item.product.name}</div>
                    <div className="cart-step-column">{item.size}</div>
                    <div className="cart-step-column">
                      {discountedPrice.toLocaleString("de-DE")},00
                    </div>
                    <div className="cart-step-column">{item.count}</div>
                    <div className="cart-step-column">
                      <Tooltip title={translatedLanguages.remove}>
                        <RemoveOutlinedIcon
                          onClick={() => removeFromCartHandler(item)}
                          className="cart-item-action-icon"
                        />
                      </Tooltip>

                      <Tooltip title={translatedLanguages.add}>
                        <AddOutlinedIcon
                          onClick={() => addToCartHandler(item)}
                          className="cart-item-action-icon"
                        />
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="cart-step-total-container"
            style={{
              justifyContent: "flex-end",
              fontSize: isSmallScreen ? "1.2em" : isMedium ? "1.3em" : "1.5em ",
            }}
          >
            <Divider
              sx={{
                width: "100%",
                backgroundColor: theme.palette.customOppositeLighter.main,
                margin: "auto",
              }}
              orientation="horizontal"
              variant="middle"
              flexItem
            />

            <div
              style={{
                display: "flex",
                padding: "2vh 2vh 2vh 0",
                justifyContent: "flex-end",
              }}
            >
              {translatedLanguages.totalLeft} &nbsp;
              <div
                style={{
                  color: "#00AC11",
                }}
              >
                {totalPrice.toLocaleString("de-DE")},00 RSD
              </div>
            </div>
            <Divider
              sx={{
                width: "100%",
                backgroundColor: theme.palette.customOppositeLighter.main,
                margin: "auto",
              }}
              orientation="horizontal"
              variant="middle"
              flexItem
            />
            <div
              style={{
                width: "100%",
                height: "10vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                textButton={translatedLanguages.checkout || "Checkout"}
                onClick={() => setCurrentStep(2)}
                endIcon={<ShoppingCartCheckoutIcon />}
                width="95%"
                backgroundColor="#97E78A"
                hoveBgColor="#65E05E"
              />
            </div>
          </div>
        </div>
      )}
      <Modal
        open={showTimeoutModal}
        onClose={handleTimeoutModalClose}
        aria-labelledby="timeout-modal"
        aria-describedby="timeout-modal-description"
      >
        <div
          className="modal-content-overlay"
          style={{
            border: `1px solid ${theme.palette.customOppositeLighter.main}`,
            width: isSmallScreen ? "80%" : isMedium ? "50%" : "40%",
            padding: "2em",
            backgroundColor: theme.palette.background.paper,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            position: "relative",
            margin: "auto",
            top: "25vh",
          }}
        >
          <CloseIcon
            onClick={handleTimeoutModalClose}
            sx={{
              fontSize: "1.3em",
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
              color: theme.palette.customOppositeLighter.main,
            }}
          />
          <h2>{translatedLanguages.timeoutMessage || "Session Expired"}</h2>
          <p>
            {translatedLanguages.sessionTimeoutExplanation ||
              "Your session has expired. Please refresh the page or continue shopping."}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              textButton={
                translatedLanguages.continueShopping || "Continue Shopping"
              }
              onClick={handleTimeoutModalClose}
              width="100%"
              backgroundColor="#97E78A"
              hoverBgColor="#65E05E"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CartStep;
