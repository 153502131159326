import React from "react";
import { Field } from "formik";
import { Checkbox } from "@mui/material";
import { getTranslation } from "../../helpers/translationUtils";
import { useTheme } from "@emotion/react";
import useGetColors from "../../helpers/hooks/useGetColors";

const FilterCheckbox = ({ filter, values, setFieldValue, language, item }) => {
  const theme = useTheme();
  const colors = useGetColors(); // Fetch colors using the custom hook
  console.log(colors);
  // Create a dynamic color map from the fetched colors
  const dynamicColorMap = colors.reduce((acc, colorObj) => {
    acc[colorObj.name.toLowerCase()] = colorObj.hex;
    return acc;
  }, {});

  // Determine the filter color dynamically based on fetched colors
  const filterColor =
    item.title === getTranslation("filters.color", language)
      ? dynamicColorMap[filter.value.toLowerCase()]
      : null;

  return (
    <div className="filter">
      <p>{filter.label}</p>
      <Field
        type="checkbox"
        name="filters"
        size="small"
        value={filter.value}
        as={Checkbox}
        sx={{
          "&.Mui-checked": {
            color: filterColor || theme.palette.customOpposite.main,
            "& svg": {
              stroke: filterColor ? "#808080" : null,
            },
          },
        }}
        checked={values.filters.includes(filter.value)}
        onChange={(e) => {
          const isChecked = e.target.checked;
          const updatedFilters = isChecked
            ? [...values.filters, e.target.value]
            : values.filters.filter((value) => value !== e.target.value);

          setFieldValue("filters", updatedFilters);
          //console.log("Updated filters:", updatedFilters);
        }}
      />
    </div>
  );
};

export default FilterCheckbox;
