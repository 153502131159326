import "./image-slider.css";
import React, { useState, useEffect, useRef } from "react";
import homepageImage1 from "../../../media/images/shoe.webp";
import homepageImage2 from "../../../media/images/shoe1.webp";
import homepageImage3 from "../../../media/images/shoe2.webp";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import { ReactComponent as LogoCircle } from "../../../media/logo/logo-circle.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useGetGalleryImages } from "../../../helpers/hooks/useGetGalleryImages";

const localImages = [
  { id: 1, url: homepageImage1 },
  { id: 2, url: homepageImage2 },
  { id: 3, url: homepageImage3 },
];

function ImageSlider() {
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const sliderContainerRef = useRef(null);
  const [sliderSize, setSliderSize] = useState({ width: 0, height: 0 });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const { galleryImages } = useGetGalleryImages();
  const images = galleryImages.length > 0 ? galleryImages : localImages;

  const isSliderReady = sliderSize.width > 0 && sliderSize.height > 0;

  useEffect(() => {
    const handleResize = () => {
      if (sliderContainerRef.current) {
        const { clientWidth, clientHeight } = sliderContainerRef.current;
        setSliderSize({ width: clientWidth, height: clientHeight });
        setIsLoaded(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isSliderReady) {
      const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % images.length);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [currentSlide, isSliderReady]);

  return (
    <div
      className="slider-container"
      ref={sliderContainerRef}
      style={{
        marginLeft: isSmallScreen ? "" : "50px",
        marginTop: isSmallScreen ? "140px" : "85px",
        width: isSmallScreen ? "100%" : "calc(100% - 50px)",
        height: isSmallScreen ? "65vh" : "80vh",
      }}
    >
      {isSliderReady && isLoaded && (
        <>
          <div
            className="slider"
            style={{
              width: `${sliderSize.width}px`,
              height: `${sliderSize.height}px`,
            }}
          >
            <div
              className="slider-inner"
              style={{
                transform: `translateX(-${currentSlide * sliderSize.width}px)`,
              }}
            >
              {images.map((image, index) => (
                <div
                key={image._id || image.id || index}
                  className="slide"
                  style={{
                    width: `${sliderSize.width}px`,
                    height: `${sliderSize.height}px`,
                  }}
                >
                  <img
                    src={image.imageURL}
                    alt={``}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          {!isSmallScreen && (
            <div className="slider-controls">
              <ArrowBackIosIcon
                className="prev-btn"
                onClick={() =>
                  setCurrentSlide(
                    (prev) => (prev - 1 + images.length) % images.length
                  )
                }
                sx={{
                  color: "darkgrey",
                  fontSize: "40px",
                  cursor: "pointer",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "white",
                  },
                }}
              />
              <ArrowForwardIosIcon
                className="next-btn"
                onClick={() =>
                  setCurrentSlide((prev) => (prev + 1) % images.length)
                }
                sx={{
                  color: "darkgrey",
                  fontSize: "40px",
                  cursor: "pointer",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "white",
                  },
                }}
              />
            </div>
          )}
          <div
            className="bullet-bar"
            style={{ gap: isSmallScreen ? "25px" : "50px" }}
          >
            {images.map((_, index) => (
              <div
                key={index}
                style={{
                  width: isSmallScreen ? "18px" : "25px",
                  height: isSmallScreen ? "18px" : "25px",
				  animationDelay: `${0.3 * (index + 1)}s`,
                }}
                className={`bullet ${currentSlide === index ? "active" : ""}`}
                onClick={() => setCurrentSlide(index)}
              ></div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ImageSlider;
