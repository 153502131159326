import { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";

const useSlideInAnimation = (threshold = 0.15, rootMargin = "0px 0px -200px 0px") => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentElement = sectionRef.current;
    if (!currentElement) {
      return;
    }

    const isMobileDevice = isMobile || window.innerWidth < 768;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);  
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        threshold: isMobileDevice ? 0.1 : threshold, 
        rootMargin: isMobileDevice ? "0px 0px -50px 0px" : rootMargin, 
      }
    );

    observer.observe(currentElement);

    return () => {
      if (currentElement) observer.unobserve(currentElement); 
    };
  }, [threshold, rootMargin]);

  return [isVisible, sectionRef];
};

export default useSlideInAnimation;
