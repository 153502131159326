import axios from "axios";
import {
  setDataState,
  displayNotification,
} from "../../../store/reducers/notificationSlice";
import { SERVER_URL, NOTIFICATION_TYPES, DATA_STATE } from "../../../helpers/app.constants";

export const getProductsByIds = async (order, dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
  if (!order || !order.products) return [];

  try {
    const products = [];
    for (const product of order.products) {
      const response = await axios.get(`${SERVER_URL}product/getproduct/${product.productID}`);

      if (response.data && response.data.success) {
        products.push(response.data.product);
      } else {
        products.push({ _id: product.productID, name: 'Product Not Found', price: 0 });
      }
    }

    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    return products;
  } catch (error) {
    console.error('Failed to fetch products:', error.response || error.message);
    
    const notificationPayload = {
      text: `Failed to fetch products: ${error.response ? error.response.data.error : error.message}`,
      type: NOTIFICATION_TYPES.ERROR,
    };
    dispatch(displayNotification(notificationPayload));
    dispatch(setDataState(DATA_STATE.DATA_STATE_ERROR));
    

    return []; 
  }
};


export const getProductById = async (productId, dispatch) => {
  dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

  try {
    const response = await axios.get(`${SERVER_URL}product/getproduct/${productId}`);
    const product = response.data.product;

    // const productColors = Array.isArray(product.color) && product.color.length === 1 
    // ? product.color[0] 
    // : product.color; 

    dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
    return product;
  } catch (error) {
    const notificationPayload = {
      text: "An error occurred while fetching the product!",
      type: NOTIFICATION_TYPES.ERROR,
    };
    dispatch(displayNotification(notificationPayload));
    dispatch(setDataState(DATA_STATE.DATA_STATE_ERROR));
    throw error;
  }
};
