import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectIsAdmin } from '../store/reducers/userSlice';

const ProtectedRoute = ({ children }) => {
  const isAdminLoggedIn = useSelector(selectIsAdmin);

  return isAdminLoggedIn ? children : <Navigate to="/admin" />;
};

export default ProtectedRoute;
