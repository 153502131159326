import { useTheme } from "@mui/material/styles";
import CustomButton from "../other/CustomButton";

const FooterButtons = ({
  translatedLanguages,
  handleReset,
  handleApply,
  isSmallScreen,
}) => {
  const theme = useTheme();

  return (
    <div
      className="filter-heading"
      style={{
        color: theme.palette.customOpposite.main,
        backgroundColor: theme.palette.primary.main1,
        border: `1px solid ${theme.palette.customOppositeLighter.main}`,
        borderLeft: isSmallScreen && "none",
        borderBottom: `1px solid ${theme.palette.customOppositeLighter.main}`,
      }}
    >
      <CustomButton
        textButton={translatedLanguages.resetFilters}
        onClick={handleReset}
        margin="5px"
        width="50%"
        padding={isSmallScreen ? "3%" : "5%"}
        backgroundColor="#FF7272"
        hoveBgColor="#FF3333"
      />
      <CustomButton
        textButton={translatedLanguages.applyFilters}
        onClick={handleApply}
        margin="5px"
        width="50%"
        padding={isSmallScreen ? "3%" : "5%"}
        backgroundColor="#97E78A"
        hoveBgColor="#65E05E"
      />
    </div>
  );
};

export default FooterButtons;
