import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filters",
  initialState: {
    allProducts: [],
    loading: false,
    filteredProducts: null,
    noProductsFound: false,
    isFilterExpanded: false,
  },
  reducers: {
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    setFilteredProducts: (state, action) => {
      if (
        typeof action.payload === "string" &&
        action.payload === "No products found"
      ) {
        state.noProductsFound = true;
        state.filteredProducts = [];
      } else {
        state.noProductsFound = false;
        state.filteredProducts = action.payload || [];
      }
    },
    setNoProductsFound: (state, action) => {
      state.noProductsFound = action.payload;
    },
    setFilterExpanded: (state, action) => {
      state.isFilterExpanded = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    clearFilters: (state) => {
      state.filters = []; 
      state.filteredProducts = []; 
    },
  },
});

export const {
  setAllProducts,
  setFilteredProducts,
  setLoading,
  setNoProductsFound,
  setFilterExpanded,
  clearFilters, 
} = filterSlice.actions;

export const selectFilteredProducts = (state) => {
  const { filteredProducts, allProducts, noProductsFound } = state.filter;

  if (noProductsFound) {
    return { products: [], noProductsFound: true };
  }

  if (filteredProducts === null) {
    return { products: allProducts, noProductsFound: false };
  }

  return { products: filteredProducts, noProductsFound: false };
};
export const selectIsFilterExpanded = (state) => state.filter.isFilterExpanded;
export const selectNoProductsFound = (state) => state.filter.noProductsFound;
export default filterSlice.reducer;
