import axios from "axios";
import { SERVER_URL } from "../../helpers/app.constants";
import { resetNewOrders } from "../../store/reducers/adminSlice";

export const processTags = (tags, discountAmount) => {
    let tagArray = tags
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag !== ""); 
  
    if (discountAmount > 0) {
      if (!tagArray.includes("sale")) {
        tagArray.push("sale");
      }
    } else {
      tagArray = tagArray.filter((tag) => tag !== "sale");
    }
  
    return tagArray.join(", ");
  };

export const resetOrdersCount = async (dispatch) => {
  try {
    const response = await axios.post(`${SERVER_URL}order/reset-orders-count`);
    if (response.data.success) {
      dispatch(resetNewOrders());
    }
  } catch (error) {
    console.error("Failed to reset orders count:", error);
  }
};

export const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((val, index) => val === arr2[index]);
};

// Simple deep comparison for objects
export const areObjectsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  
  return keys1.every(key => obj1[key] === obj2[key]);
};

export const deepEqual = (obj1, obj2) => {
  // If both values are strictly equal
  if (obj1 === obj2) return true;

  // If either is not an object or is null, return false (since they are not equal)
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // Get object keys for both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the number of keys is different, the objects are not equal
  if (keys1.length !== keys2.length) return false;

  // Check if all keys and values are deeply equal
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

