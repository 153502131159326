import React from "react";
import "./footer.css";
import { isMobile } from "react-device-detect";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { Divider, useMediaQuery } from "@mui/material";
import { ReactComponent as LogoFooter } from "../../media/logo/logo.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PaymentIcon from "@mui/icons-material/Payment";
import { translations } from "../../helpers/translations";
import { useLocation, useNavigate } from "react-router-dom";
import { CONTACT_INFO } from "../../helpers/app.constants";

function Footer() {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const isMedium = useMediaQuery("(max-width: 1400px)");
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const navigate = useNavigate();
  const t = (key) => translatedLanguages[key] || "";
  const location = useLocation();

  const isHomeRoute =
    location.pathname === "/" || location.pathname === "/about";
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert(`${text} copied to clipboard!`);
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  return (
    <div
      className="footer-container"
      style={{
        marginLeft: isSmallScreen ? "" : "50px",
        width: isSmallScreen ? "100%" : "calc(100% - 50px)",
        height: isSmallScreen ? "30vh" : "25vh",
        borderTop: `1px solid ${theme.palette.customOppositeLighter.main}`,
        padding: isSmallScreen ? "2vh 0" : "0.5vh 0",
        justifyItems: isSmallScreen ? "center" : "center",
        marginTop: isHomeRoute
          ? "0"
          : isSmallScreen || isMedium
          ? "8vh"
          : "5vh",
      }}
    >
      <div>{isSmallScreen && <LogoFooter width={80} height={80} />}</div>
      <div
        className="footer-grid"
        style={{
          display: "grid",
          gridTemplateColumns: isSmallScreen
            ? "repeat(2, 1fr)"
            : "repeat(4, 1fr)",
          width: isSmallScreen ? "100%" : "95%",
        }}
      >
        <div
          className="footer-column"
          style={{
            flexDirection: isSmallScreen ? "column" : "row",
            width: isSmallScreen ? "90%" : "",
          }}
        >
          <Divider
            style={{
              backgroundColor: theme.palette.customOppositeLighter.main,
              width: isSmallScreen ? "auto" : "0.3px",
            }}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant="middle"
            flexItem
          />

          <div
            className="footer-column-container"
            style={{ paddingLeft: isSmallScreen ? "3vh" : "" }}
          >
            <div className="footer-heading">{t("followUs")}</div>
            <div className="footer-section">
              {!isSmallScreen && (
                <InstagramIcon sx={{ marginRight: "1px", fontSize: "1.5em" }} />
              )}
              <a
                href="https://www.instagram.com/soulandsoledanceshoes?igsh=Zzk5Ym9zYjc5Y2t2&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Follow us on Instagram
              </a>
            </div>
            {/* <div className="footer-section">
              {!isSmallScreen && (
                <FacebookOutlinedIcon
                  sx={{ marginRight: "1px", fontSize: "1.5em" }}
                />
              )}
              Other Placeholder
            </div> */}
          </div>
        </div>

        <div
          className="footer-column"
          style={{
            flexDirection: isSmallScreen ? "column" : "row",
            width: isSmallScreen ? "90%" : "",
          }}
        >
          <Divider
            style={{
              backgroundColor: theme.palette.customOppositeLighter.main,
              width: isSmallScreen ? "auto" : "0.3px",
            }}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant="middle"
            flexItem
          />
          <div
            className="footer-column-container"
            style={{ paddingLeft: isSmallScreen ? "3vh" : "" }}
          >
            <div className="footer-heading">{t("contactUs")}</div>
            <div
              className="footer-section"
              onClick={() => copyToClipboard(CONTACT_INFO.SUPPORT_EMAIL)}
            >
              {!isSmallScreen && (
                <MailOutlineIcon
                  sx={{ marginRight: "1px", fontSize: "1.5em" }}
                />
              )}
              <span style={{ width: isSmallScreen && "80%" }}>
                {CONTACT_INFO.SUPPORT_EMAIL}
              </span>
            </div>
            <div
              className="footer-section"
              onClick={() => copyToClipboard(CONTACT_INFO.SUPPORT_PHONE)}
            >
              {!isSmallScreen && (
                <PhoneOutlinedIcon
                  sx={{ marginRight: "1px", fontSize: "1.5em" }}
                />
              )}
              {CONTACT_INFO.SUPPORT_PHONE}
            </div>
          </div>
        </div>

        <div
          className="footer-column"
          style={{
            flexDirection: isSmallScreen ? "column" : "row",
            width: isSmallScreen ? "90%" : "",
          }}
        >
          <Divider
            style={{
              backgroundColor: theme.palette.customOppositeLighter.main,
              width: isSmallScreen ? "auto" : "0.3px",
            }}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant="middle"
            flexItem
          />
          <div
            className="footer-column-container"
            style={{ paddingLeft: isSmallScreen ? "3vh" : "" }}
          >
            <div className="footer-heading">{t("links")}</div>
            <div className="footer-section" onClick={() => navigate("/about")}>
              {!isSmallScreen && (
                <InfoOutlinedIcon
                  sx={{ marginRight: "1px", fontSize: "1.5em" }}
                />
              )}
              {t("aboutUs")}
            </div>
            <div className="footer-section" onClick={() => navigate("/shop")}>
              {!isSmallScreen && (
                <Inventory2OutlinedIcon
                  sx={{ marginRight: "1px", fontSize: "1.5em" }}
                />
              )}
              {t("shop")}
            </div>
          </div>
        </div>

        <div
          className="footer-column"
          style={{
            flexDirection: isSmallScreen ? "column" : "row",
            width: isSmallScreen ? "90%" : "",
          }}
        >
          <Divider
            style={{
              backgroundColor: theme.palette.customOppositeLighter.main,
              width: isSmallScreen ? "auto" : "0.3px",
            }}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant="middle"
            flexItem
          />
          <div
            className="footer-column-container"
            style={{ paddingLeft: isSmallScreen ? "3vh" : "" }}
          >
            <div className="footer-heading">{t("salesInfo")}</div>
            <div
              className="footer-section"
              onClick={() => navigate("/about#privacy-policy")}
            >
              {!isSmallScreen && (
                <HelpOutlineIcon
                  sx={{ marginRight: "1px", fontSize: "1.5em" }}
                />
              )}
              {t("privacyPolicy")}
            </div>
            <div
              className="footer-section"
              onClick={() => navigate("/about#terms-conditions")}
            >
              {!isSmallScreen && (
                <PaymentIcon sx={{ marginRight: "1px", fontSize: "1.5em" }} />
              )}
              {t("terms")}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          fontSize: isSmallScreen ? "0.6em" : "0.7em",
          marginTop: isSmallScreen ? "1vh" : "",
        }}
      >
        © 2024 Soul And Sole. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
