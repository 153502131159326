import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	allProducts: [],
};

const productSlice = createSlice({
	name: "product",
	initialState,
	reducers: {
		setAllProducts: (state, action) => {
		  return {
			...state,
			allProducts: Array.isArray(action.payload) ? [...action.payload] : [], 
		  };
		},
	  },
});

export const { setAllProducts } = productSlice.actions;

export const selectAllProducts = (state) => state.product.allProducts;
export const selectProductById = (state, productId) => {
	return state.product.allProducts.find((product) => product._id === productId);
};

export default productSlice.reducer;
