import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectActiveLanguage,
	setLanguage,
} from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import LanguageOption from "./LanguageOption";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

function LanguageModalMobile({ onClose }) {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const isIOS = () => {
		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	};

	const languageOptions = [
		{ code: "en", label: translatedLanguages.english || "English" },
		{ code: "sr", label: translatedLanguages.serbian || "Serbian" },
	];

	const onLanguageSelect = (language) => {
		dispatch(setLanguage({ language }));
		onClose();
	};

	const scrollToBottomAndClose = () => {
		onClose();
		setTimeout(() => {
			window.scrollTo({
				top: document.documentElement.scrollHeight,
				behavior: "smooth",
			});
		}, 300);
	};

	const activeLanguage =
		currentLanguage === "en"
			? translatedLanguages.english
			: currentLanguage === "sr"
			? translatedLanguages.serbian
			: "Unknown Language";

	return (
		<div className="language-mobile-modal-container">
			<div className="language-mobile-modal-link-container">
				<div
					className="language-mobile-modal-link"
					onClick={() => {
						navigate("/about");
						onClose();
					}}
				>
					{translatedLanguages.aboutUs}
				</div>
				<div
					className="language-mobile-modal-link"
					onClick={() => {
						navigate("/shop");
						onClose();
					}}
				>
					{translatedLanguages.shop}
				</div>
				<div
					className="language-mobile-modal-link"
					onClick={scrollToBottomAndClose}
				>
					{translatedLanguages.contact}
				</div>
			</div>

			<div
				className="language-mobile-container"
				style={{ paddingBottom: isIOS ? "10vh" : "5vh" }}
			>
				<div className="language-mobile-modal">
					{languageOptions.map(({ code, label }, index) => (
						<React.Fragment key={code}>
							<LanguageOption
								isActive={code === currentLanguage}
								language={{ code, label }}
								onClick={() => onLanguageSelect(code)}
								style={{ border: code === currentLanguage }}
							/>

							{index < languageOptions.length - 1 && (
								<Divider
									style={{
										backgroundColor: theme.palette.customOppositeLighter.main,
									}}
									orientation="vertical"
									variant="middle"
									flexItem
								/>
							)}
						</React.Fragment>
					))}
				</div>

				<div className="language-mobile-modal-label">{activeLanguage}</div>
			</div>
		</div>
	);
}

export default LanguageModalMobile;
