
import "./about-us.css";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../../store/reducers/userSlice.js";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { translations } from "../../../helpers/translations.js";
import aboutVideo from "../../../media/images/aboutSlower.mp4";
import { useMediaQuery } from "@mui/material";
import { ReactComponent as Heading } from "../../../media/logo/filled-black-en.svg";
import { ReactComponent as HeadingSr } from "../../../media/logo/filled-black-sr.svg";
import { useTheme } from "@emotion/react";
import CustomButton from "../../other/CustomButton.js";
import { useNavigate } from "react-router-dom";
import useSlideInAnimation from "../../../helpers/hooks/useSlideInAnimation.js";

function AboutUs() {
	const theme = useTheme();
	const navigate = useNavigate();
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const isSmallMedium = useMediaQuery("(max-width: 770px)");
	const isMedium = useMediaQuery("(max-width: 1400px)");
	const isMediumBigger = useMediaQuery("(max-width: 850px)");
	const [isHeaderVisible, headerRef] = useSlideInAnimation(
		0.2,
		"0px 0px -200px 0px",
	);
	const [isContentVisible, contentRef] = useSlideInAnimation(
		0.4,
		"0px 0px -150px 0px",
	);
	const [isButtonVisible, buttonRef] = useSlideInAnimation(
		0.6,
		"0px 0px -100px 0px",
	);
	return (
		<div
			className="about-us"
			style={{
				marginLeft: isSmallScreen ? "" : "50px",
				width: isSmallScreen ? "100%" : "calc(100% - 50px)",
				height: isSmallScreen ? "75vh" : isMedium ? "80vh" : "85vh",
				alignItems: isSmallScreen ? "center" : "flex-start",
				flexDirection:
					isSmallScreen || isSmallMedium
						? "column"
						: isMedium
						? "column"
						: "column",
			}}
		>
			<video
				src={aboutVideo}
				autoPlay
				playsInline
				loop
				muted
				className="background-video"
			/>

			<div
				className="about-us-content"
				style={{
					width: isSmallScreen ? "100%" : isMediumBigger ? "45%" : "35%",
					backgroundColor: isSmallScreen
						? theme.palette.primary.opacity75
						: theme.palette.primary.opacity80,
					minWidth: isSmallScreen && "350px",
				}}
			>
				<div
					className="about-us-content-inner"
					style={{
						width: isSmallScreen ? "85%" : isSmallMedium ? "85%" : "80%",
					}}
				>
					<div>
						<h1
							ref={headerRef}
							className={`about-us-header ${isHeaderVisible ? "slide-in" : ""}`}
							style={{marginBottom: isSmallScreen ? "" : isMediumBigger ? "5vh" : "7vh",}}
						>
							{currentLanguage === "en" ? (
								<Heading sx={{ minHeight: isSmallScreen ? "" : "200px" }} />
							) : (
								<HeadingSr sx={{ minHeight: isSmallScreen ? "" : "200px" }} />
							)}
							{/* {translatedLanguages.heading} */}
						</h1>
						<div
							ref={contentRef}
							className={`about-us-paragraphs ${
								isContentVisible ? "slide-in" : ""
							}`}
						>
							<div
								style={{
									fontSize: isSmallScreen
										? "0.85em"
										: isMediumBigger
										? "0.80em" 
										: isMedium 
										? "0.85em"
										: "0.95em",
									textAlign: "justify",
									wordSpacing: "0.05em",
									hyphens: "auto",
									lineHeight: isSmallScreen ? "1" : "1.2",
								}}
								className="about-us-p"
							>
								{translatedLanguages.content
									.split("\n\n")
									.slice(0, 2)
									.map((paragraph, index, array) => (
										<p key={index}>
											{paragraph}
											{index === array.length - 1 && "..."}
										</p>
									))}
							</div>
						</div>
					</div>
					<div
						ref={buttonRef}
						className={`about-us-button ${isButtonVisible ? "slide-in" : ""}`}
					>
						<CustomButton
							textButton={translatedLanguages.aboutUs || "About Us"}
							onClick={() => navigate("/about")}
							width="100%"
							display="flex"
							justifyContent="center"
							margin="2vh 0"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AboutUs;
