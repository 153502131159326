import { Slider, Input } from "@mui/material";
import { useEffect } from "react";

const PriceSlider = ({
  minPrice,
  maxPrice,
  handleMinPriceChange,
  handleMaxPriceChange,
  setFieldValue,
  //submitForm,
}) => {

  useEffect(() => {
    setFieldValue("minPriceFormik", minPrice, false);
    setFieldValue("maxPriceFormik", maxPrice, false);
    //submitForm();
  }, [minPrice, maxPrice, setFieldValue]);


  const handleInputChange = (type, value) => {
    const newValue = Number(value) || 0;
    if (type === "min") {
      handleMinPriceChange(newValue);
      setFieldValue("minPriceFormik", newValue, false);
    } else if (type === "max") {
      handleMaxPriceChange(newValue);
      setFieldValue("maxPriceFormik", newValue, false);
    }
  };

  const handleInputFocus = (type) => {
    if (type === "min" && minPrice === 0) {
      setFieldValue("minPriceFormik", "", false);
    } else if (type === "max" && maxPrice === 0) {
      setFieldValue("maxPriceFormik", "", false);
    }
  };

  const handleInputBlur = (type) => {
    if (type === "min" && !minPrice) {
      handleMinPriceChange(0);
      setFieldValue("minPriceFormik", 0, false);
    } else if (type === "max" && !maxPrice) {
      handleMaxPriceChange(0);
      setFieldValue("maxPriceFormik", 0, false);
    }
  };

  return (
    <div className="slider-container">
      <div className="slider-title-container">
        <div className="slider-title">
          <div>Min:</div>
          <Input
            className="price-input"
            value={minPrice}
            onFocus={() => handleInputFocus("min")}
            onBlur={() => handleInputBlur("min")}
            onChange={(e) => handleInputChange("min", e.target.value)}
            inputProps={{ step: 1, min: 0, max: maxPrice, type: "number" }}
          />
        </div>
        <div className="slider-title">
          <div>Max:</div>
          <Input
            className="price-input"
            value={maxPrice}
            onFocus={() => handleInputFocus("max")}
            onBlur={() => handleInputBlur("max")}
            onChange={(e) => handleInputChange("max", e.target.value)}
            inputProps={{ step: 1, min: minPrice, max: 50000, type: "number" }}
          />
        </div>
      </div>
      <Slider
        sx={{ mt: "20px", width: "70%" }}
        value={[minPrice, maxPrice]}
        onChange={(event, newValue) => {
          const [min, max] = newValue;
          handleMinPriceChange(min);
          setFieldValue("minPriceFormik", min, false); 
          handleMaxPriceChange(max);
          setFieldValue("maxPriceFormik", max, false); 
        }}
        min={0}
        max={50000}
        aria-labelledby="input-slider"
      />
    </div>
  );
};

export default PriceSlider;
