// translationUtils.js
import { translations } from './translations';

export const getTranslation = (key, language = 'en') => {
  const keys = key.split('.');
  let translation = translations[language];

  keys.forEach(k => {
    translation = translation?.[k];
  });

  // If translation is not found, return the last part of the key with the first letter capitalized
  if (!translation) {
    const fallback = keys[keys.length - 1]; // Get the last segment, e.g., "jeans"
    return fallback.charAt(0).toUpperCase() + fallback.slice(1); // Capitalize the first letter
  }

  return translation;
};
