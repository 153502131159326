import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
  ClickAwayListener,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChromePicker } from "react-color";
import CustomField from "../../components/other/CustomField";
import { useDispatch } from "react-redux";
import {
  addColor,
  deleteColor,
  editColor,
  getColors,
} from "../adminPanel.actions";

const AdminColorManager = () => {
  const dispatch = useDispatch();
  const [colors, setColors] = useState([]);
  const [newColorName, setNewColorName] = useState("");
  const [newColorHex, setNewColorHex] = useState("#FFFFFF");
  const [editColorId, setEditColorId] = useState(null);
  const [editColorName, setEditColorName] = useState("");
  const [editColorHex, setEditColorHex] = useState("#FFFFFF");
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [activeColorPicker, setActiveColorPicker] = useState(null); // Track which color is being edited

  // Fetch colors from the backend when the component mounts
  useEffect(() => {
    dispatch(getColors())
      .then((fetchedColors) => setColors(fetchedColors))
      .catch((error) => console.error("Error fetching colors:", error));
  }, [dispatch]);

  const handleAddColor = () => {
    if (newColorName.trim() !== "") {
      addColor(
        newColorName,
        newColorHex
      )(dispatch)
        .then((newColorFromServer) =>
          setColors([...colors, newColorFromServer])
        )
        .catch((error) => console.error("Error adding color:", error));
      setNewColorName("");
      setNewColorHex("#FFFFFF");
    }
  };

  const handleDeleteColor = (id) => {
    deleteColor(id)(dispatch)
      .then(() => setColors(colors.filter((color) => color._id !== id)))
      .catch((error) => console.error("Error deleting color:", error));
  };

  const handleEditColor = (id, name, hex) => {
    setEditColorId(id);
    setEditColorName(name);
    setEditColorHex(hex);
    setActiveColorPicker(id); // Set the active color picker for this specific color
  };

  const handleSaveEditColor = () => {
    if (editColorId && editColorName.trim() !== "") {
      editColor(
        editColorId,
        editColorName,
        editColorHex
      )(dispatch)
        .then(() => {
          setColors(
            colors.map((color) =>
              color._id === editColorId
                ? { ...color, name: editColorName, hex: editColorHex }
                : color
            )
          );
        })
        .catch((error) => console.error("Error editing color:", error));
      setEditColorId(null);
      setEditColorName("");
      setEditColorHex("#FFFFFF");
      setActiveColorPicker(null); // Reset the active color picker after saving
    }
  };

  const handleClickAway = () => {
    setActiveColorPicker(null); // Close the color picker on click away
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Manage Colors</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <CustomField
              label="Add New Color Name"
              value={newColorName}
              onChange={(e) => setNewColorName(e.target.value)}
              width="300px"
              size="small"
              regular={true}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                sx={{
                  minWidth: "30px",
                  height: "30px",
                  backgroundColor: newColorHex,
                  border: "1px solid #ddd",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => setColorPickerVisible(!colorPickerVisible)}
              />
              {colorPickerVisible && (
                <ClickAwayListener
                  onClickAway={() => setColorPickerVisible(false)}
                >
                  <Box sx={{ position: "absolute", zIndex: 100 }}>
                    <ChromePicker
                      color={newColorHex}
                      onChange={(color) => setNewColorHex(color.hex)}
                    />
                  </Box>
                </ClickAwayListener>
              )}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddColor}
            >
              Add Color
            </Button>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {colors.map((color, index) => (
              <Box
                key={color._id || index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "8px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                {editColorId === color._id ? (
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <CustomField
                      label="Edit Color Name"
                      value={editColorName}
                      onChange={(e) => setEditColorName(e.target.value)}
                      width="300px"
                      size="small"
                      regular={true}
                    />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Button
                        sx={{
                          minWidth: "30px",
                          height: "30px",
                          backgroundColor: editColorHex,
                          border: "1px solid #ddd",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={() => setActiveColorPicker(color._id)}
                      />
                      {activeColorPicker === color._id && (
                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Box sx={{ position: "absolute", zIndex: 100 }}>
                            <ChromePicker
                              color={editColorHex}
                              onChange={(color) => setEditColorHex(color.hex)}
                            />
                          </Box>
                        </ClickAwayListener>
                      )}
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveEditColor}
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Box sx={{display:"flex"}}>
                      <Typography style={{ width: "150px" }}>
                        {color.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          width: "150px",
                        }}
                      >
                        {/* Add the color preview circle here */}
                        <Box
                          sx={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: color.hex,
                            borderRadius: "50%",
                            border: "1px solid #ddd",
                          }}
                        />
                        <Typography>{color.hex}</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        onClick={() =>
                          handleEditColor(color._id, color.name, color.hex)
                        }
                        sx={{ color: "blue" }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteColor(color._id)}
                        sx={{ color: "red" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminColorManager;
