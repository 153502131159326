import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import {
  addFeaturedCategory,
  removeFeaturedCategory,
} from "../adminPanel.actions";
import socket from "../../helpers/socket"; // Import the socket
import useGetTags from "../../helpers/hooks/useGetTags";
import useGetFeaturedCategories from "../../helpers/hooks/useGetFeaturedCategories";

const AdminFeaturedCategories = () => {
  const dispatch = useDispatch();
  const tags = useGetTags(); // Fetch tags dynamically
  const featuredCategories = useGetFeaturedCategories(); // Fetch featured categories dynamically
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tagList, setTagList] = useState([]); // Local state for tags

  // Initialize the tag list from fetched tags
  useEffect(() => {
    if (tags.length) {
      setTagList(tags); // Set the initial tags
    }
  }, [tags]);

  // Initialize selected categories based on fetched featured categories
  useEffect(() => {
    if (featuredCategories.length) {
      const categories = featuredCategories.map((category) => ({
        id: category._id,
        name: category.name,
      }));
      setSelectedCategories(categories);
      console.log("Selected Categories:", categories); // Log to check the populated categories
    }
  }, [featuredCategories]);

  // Socket listeners for tag updates
  useEffect(() => {
    socket.on("tagAdded", (newTag) => {
      setTagList((prevTags) => [...prevTags, newTag]); // Update the tags list
    });

    socket.on("tagEdited", (editedTag) => {
      setTagList((prevTags) =>
        prevTags.map((tag) => (tag._id === editedTag._id ? editedTag : tag))
      );
    });

    socket.on("tagDeleted", (deletedTagName) => {
      setTagList((prevTags) =>
        prevTags.filter((tag) => tag.name.toLowerCase() !== deletedTagName.toLowerCase())
      );
      setSelectedCategories((prevSelectedCategories) =>
        prevSelectedCategories.filter(
          (category) => category.name.toLowerCase() !== deletedTagName.toLowerCase()
        )
      );
    });

    // Cleanup when component unmounts
    return () => {
      socket.off("tagAdded");
      socket.off("tagEdited");
      socket.off("tagDeleted");
    };
  }, []);

  const handleCategoryChange = (tagId, tagName) => {
    console.log("Toggling category:", { id: tagId, name: tagName });

    setSelectedCategories((prevSelectedCategories) => {
      // Check if the category is already selected by either ID or name
      const isCategorySelected = prevSelectedCategories.some(
        (category) => category.id === tagId || category.name === tagName
      );

      console.log("Current selected categories:", prevSelectedCategories);
      console.log("Is category selected:", isCategorySelected);

      if (isCategorySelected) {
        // If the category is already selected, remove it
        console.log("Removing category:", { id: tagId, name: tagName });

        // Optimistically update the state by removing the category
        const updatedCategories = prevSelectedCategories.filter(
          (category) => category.id !== tagId && category.name !== tagName
        );

        // Perform the async action to remove the category from the server
        dispatch(removeFeaturedCategory(tagId))
          .then(() => {
            console.log("Successfully removed category:", { id: tagId, name: tagName });
          })
          .catch((error) => {
            // Rollback the state if the request fails
            setSelectedCategories((rollbackCategories) => [
              ...rollbackCategories,
              { id: tagId, name: tagName },
            ]);
            console.error("Error removing category:", error);
          });

        return updatedCategories;
      } else {
        // If the category is not selected, add it
        console.log("Adding category:", { id: tagId, name: tagName });

        // Optimistically update the state by adding the category
        const updatedCategories = [
          ...prevSelectedCategories,
          { id: tagId, name: tagName },
        ];

        // Perform the async action to add the category to the server
        dispatch(addFeaturedCategory({ id: tagId, name: tagName }))
          .then(() => {
            console.log("Successfully added category:", { id: tagId, name: tagName });
          })
          .catch((error) => {
            // Rollback the state if the request fails
            setSelectedCategories((rollbackCategories) =>
              rollbackCategories.filter((category) => category.id !== tagId)
            );
            console.error("Error adding category:", error);
          });

        return updatedCategories;
      }
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Manage Featured Product Categories</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography variant="subtitle1" mb={2}>
            Selected Categories:{" "}
            {selectedCategories.map((category) => category.name).join(", ") ||
              "None"}
          </Typography>
          {tagList.map((tag) => (
            <Box
              key={tag._id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Checkbox
                checked={selectedCategories.some(
                  (category) => tag.name === category.name // Compare by name temporarily to test if the checkboxes work
                )}
                onChange={() => handleCategoryChange(tag._id, tag.name)}
                sx={{
                  "&.Mui-checked": {
                    color: "black", // Set the checkbox color to black when checked
                  },
                }}
              />
              <Typography>{tag.name}</Typography>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminFeaturedCategories;
