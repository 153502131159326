import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getFeaturedCategories } from "../../adminPanel/adminPanel.actions";

const useGetFeaturedCategories = () => {
  const dispatch = useDispatch();
  const [featuredCategories, setFeaturedCategories] = useState([]);

  useEffect(() => {
    const fetchFeaturedCategories = async () => {
      const fetchedCategories = await dispatch(getFeaturedCategories());
      // console.log("Fetched Featured Categories:", fetchedCategories); 
      setFeaturedCategories(fetchedCategories);
    };

    fetchFeaturedCategories();
  }, [dispatch]);

  return featuredCategories;
};

export default useGetFeaturedCategories;
