import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  setDataState,
  displayNotification,
} from "../../store/reducers/notificationSlice";
import { DATA_STATE, NOTIFICATION_TYPES, SERVER_URL } from "../app.constants";
import { setAllProducts } from "../../store/reducers/productSlice";
import socket from "../socket";

const useGetAllProducts = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));

    const fetchAllProducts = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}product/getallproducts`);
        const products = response.data.products || [];
        dispatch(setAllProducts(products)); 
        setProducts(products)
      } catch (error) {
        dispatch(displayNotification({
          text: "Server error!",
          type: NOTIFICATION_TYPES.ERROR,
        }));
      } finally {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
      }
    };

    fetchAllProducts();
  }, [dispatch]);

 useEffect(() => {
    socket.on("productAdded", (data) => {
      setProducts((prevProducts) => {
        const updatedProducts = [...prevProducts, data.product];
        dispatch(setAllProducts(updatedProducts)); 
        return updatedProducts;
      });
    });

    socket.on("productUpdated", (data) => {
      setProducts((prevProducts) => {
        const updatedProducts = prevProducts.map((product) =>
          product._id === data.product._id ? data.product : product
        );
        dispatch(setAllProducts(updatedProducts)); 
        return updatedProducts;
      });
    });

    socket.on("productDeleted", ({ productId }) => {
      setProducts((prevProducts) => {
        const updatedProducts = prevProducts.filter(
          (product) => product._id !== productId
        );
        dispatch(setAllProducts(updatedProducts)); 
        return updatedProducts;
      });
    });

    return () => {
      socket.off("productAdded");
      socket.off("productUpdated");
      socket.off("productDeleted");
    };
  }, [dispatch]);

  return products; 
};

export default useGetAllProducts;
