import React, { useMemo, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Checkbox, IconButton, InputAdornment } from "@mui/material";
import { addProduct } from "../adminPanel.actions";
import { displayNotification } from "../../store/reducers/notificationSlice";
import InputFieldFormik from "../../components/formik/InputFieldFormik";
import "./admin-panel-add-product.css";
import CustomButton from "../../components/other/CustomButton";
import { NOTIFICATION_TYPES } from "../../helpers/app.constants";
import { processTags } from "../components/adminUtils";
import CustomDropdown from "../components/CustomDropdown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useGetColors from "../../helpers/hooks/useGetColors";
import useGetTags from "../../helpers/hooks/useGetTags";

const FORM_FIELDS = {
  NAME: "name",
  PRICE: "price",
  DISCOUNT_AMOUNT: "discountAmount",
  TAGS: "tags",
  COLORS: "colors",
  SIZE: "size",
  HEEL_HEIGHT: "heelHeight",
  OUT_OF_STOCK_SIZES: "outOfStockSizes",
  OUT_OF_STOCK: "outOfStock",
  IMAGE: "image",
  IMAGE2: "image2",
  IMAGE3: "image3",
};

const AdminPanelAddProduct = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [showTagDropdown, setShowTagDropdown] = useState(false);
  const [showColorDropdown, setShowColorDropdown] = useState(false);

  const colors = useGetColors(); 
  const tags = useGetTags();  

  const imageInputRef = useRef(null);
  const image2InputRef = useRef(null);
  const image3InputRef = useRef(null);

  const initialValues = useMemo(() => {
    return {
      [FORM_FIELDS.NAME]: "",
      [FORM_FIELDS.PRICE]: "",
      [FORM_FIELDS.DISCOUNT_AMOUNT]: "",
      [FORM_FIELDS.TAGS]: "",
      [FORM_FIELDS.COLORS]: "",
      [FORM_FIELDS.SIZE]: "",
      [FORM_FIELDS.OUT_OF_STOCK_SIZES]: "",
      [FORM_FIELDS.HEEL_HEIGHT]: "",
      [FORM_FIELDS.OUT_OF_STOCK]: false,
      [FORM_FIELDS.IMAGE]: null,
      [FORM_FIELDS.IMAGE2]: null,
      [FORM_FIELDS.IMAGE3]: null,
    };
  }, []);

  const validationSchema = Yup.object().shape({
    [FORM_FIELDS.NAME]: Yup.string().required("Product name is required!"),
    [FORM_FIELDS.PRICE]: Yup.number()
      .required("Product price is required!")
      .min(1, "Price must be greater than 0!"),
    [FORM_FIELDS.DISCOUNT_AMOUNT]: Yup.mixed()
      .transform((value) => (value === "" || value === undefined ? 0 : value))
      .test(
        "is-number",
        "Discount must be a valid number!",
        (value) => !isNaN(value)
      ),
    [FORM_FIELDS.TAGS]: Yup.string().required("Tags are required!"),
    [FORM_FIELDS.COLORS]: Yup.string().required("Colors are required!"),
    [FORM_FIELDS.SIZE]: Yup.string()
      .required("Size is required!")
      .test("valid-sizes", "Sizes must be valid numbers!", (value) => {
        const sizesArray = value.split(",").map((size) => size.trim());
        return sizesArray.every((size) => !isNaN(size) && parseFloat(size) > 0);
      }),
    [FORM_FIELDS.OUT_OF_STOCK_SIZES]: Yup.string()
      .nullable()
      .test("valid-sizes", "Sizes must be valid numbers!", (value) => {
        if (!value) return true;
        const sizesArray = value.split(",").map((size) => size.trim());
        return sizesArray.every((size) => !isNaN(size) && parseFloat(size) > 0);
      }),
    [FORM_FIELDS.HEEL_HEIGHT]: Yup.number()
      .required("Heel height is required!")
      .min(1, "Heel height must be greater than 0!"),
    [FORM_FIELDS.IMAGE]: Yup.mixed().nullable(),
    [FORM_FIELDS.IMAGE2]: Yup.mixed().nullable(),
    [FORM_FIELDS.IMAGE3]: Yup.mixed().nullable(),
  });

  const onSubmit = async (
    formValues,
    { setFieldError, resetForm, setFieldValue }
  ) => {
    const formData = new FormData();

    const processedTags = processTags(
      formValues.tags,
      formValues.discountAmount
    );

    const cleanedColors = formValues.colors
      .split(",")
      .map((color) => color.trim())
      .filter((color) => color !== "");

    formData.append("name", formValues.name);
    formData.append("price", formValues.price);
    formData.append("discountAmount", formValues.discountAmount || 0);
    formData.append("tags", processedTags);
    cleanedColors.forEach((color) => {
      formData.append("colors[]", color);
    });
    formData.append("size", formValues.size);
    formData.append("outOfStockSizes", formValues.outOfStockSizes);
    formData.append("heelHeight", formValues.heelHeight);
    formData.append("outOfStock", formValues.outOfStock);

    if (formValues.image) formData.append("image", formValues.image);
    if (formValues.image2) formData.append("image2", formValues.image2);
    if (formValues.image3) formData.append("image3", formValues.image3);

    try {
      await dispatch(addProduct(formData));

      resetForm();

      setImage(null);
      setImage2(null);
      setImage3(null);

      if (imageInputRef.current) imageInputRef.current.value = "";
      if (image2InputRef.current) image2InputRef.current.value = "";
      if (image3InputRef.current) image3InputRef.current.value = "";

      setFieldValue(FORM_FIELDS.IMAGE, null, false);
      setFieldValue(FORM_FIELDS.IMAGE2, null, false);
      setFieldValue(FORM_FIELDS.IMAGE3, null, false);
    } catch (error) {
      console.error("Full error:", error);

      const errorMessage =
        error.response?.data?.error || "An error occurred. Please try again.";
      setFieldError(FORM_FIELDS.NAME, errorMessage);
      dispatch(
        displayNotification({
          text: errorMessage,
          type: NOTIFICATION_TYPES.ERROR,
        })
      );
    }
  };

  const colorOptions = colors.map((color) => ({
    value: color.hex,
    label: color.name,
  }));

  const tagOptions = tags.map((tag) => ({
    value: tag._id,
    label: tag.name,
  }));

  return (
    <div className="admin-add-product-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isValid, setFieldValue, errors, touched }) => {
          let displayedTags = Array.isArray(values.tags)
            ? values.tags
            : values.tags
                .split(",")
                .map((tag) => tag.trim())
                .filter((tag) => tag !== "");

          // Automatically add the 'sale' tag if discount is greater than 0
          if (values.discountAmount > 0 && !displayedTags.includes("sale")) {
            displayedTags.push("sale");
            setFieldValue(FORM_FIELDS.TAGS, displayedTags.join(", "));
          }

          // Remove 'sale' tag if discount is 0
          if (values.discountAmount === 0 && displayedTags.includes("sale")) {
            displayedTags = displayedTags.filter((tag) => tag !== "sale");
            setFieldValue(FORM_FIELDS.TAGS, displayedTags.join(", "));
          }

          const discountAmount = values.discountAmount;
          const price = values.price;
          let discountedPrice = price;

          if (discountAmount && price) {
            discountedPrice = price - (price * discountAmount) / 100;
          }

          const formattedDiscountedPrice = new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(discountedPrice);

          return (
            <Form className="admin-add-product-form">
              <Field name={FORM_FIELDS.OUT_OF_STOCK}>
                {({ form, ...formik }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Checkbox
                      checked={values[FORM_FIELDS.OUT_OF_STOCK]}
                      onChange={() =>
                        form.setFieldValue(
                          FORM_FIELDS.OUT_OF_STOCK,
                          !values[FORM_FIELDS.OUT_OF_STOCK]
                        )
                      }
                      sx={{
                        color: "black",
                        "&.Mui-checked": {
                          color: "black",
                        },
                      }}
                    />
                    <label style={{ marginLeft: "8px" }}>Out of stock</label>
                  </div>
                )}
              </Field>

              <div className="admin-form-images-row">
                <div className="admin-form-group-images">
                  <label htmlFor="image">Image 1</label>
                  <label htmlFor="image" className="custom-file-upload">
                    Upload Image 1
                  </label>
                  <input
                    ref={imageInputRef}
                    id="image"
                    type="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue(FORM_FIELDS.IMAGE, file);
                      setImage(file ? URL.createObjectURL(file) : null);
                    }}
                  />
                  {image && (
                    <img
                      className="admin-form-image"
                      src={image}
                      alt="Preview 1"
                    />
                  )}
                </div>

                <div className="admin-form-group-images">
                  <label htmlFor="image2">Image 2</label>
                  <label htmlFor="image2" className="custom-file-upload">
                    Upload Image 2
                  </label>
                  <input
                    ref={image2InputRef}
                    id="image2"
                    type="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue(FORM_FIELDS.IMAGE2, file);
                      setImage2(file ? URL.createObjectURL(file) : null);
                    }}
                  />
                  {image2 && (
                    <img
                      className="admin-form-image"
                      src={image2}
                      alt="Preview 2"
                    />
                  )}
                </div>

                <div className="admin-form-group-images">
                  <label htmlFor="image3">Image 3</label>
                  <label htmlFor="image3" className="custom-file-upload">
                    Upload Image 3
                  </label>
                  <input
                    ref={image3InputRef}
                    id="image3"
                    type="file"
                    onChange={(e) => {
                      const file = e.currentTarget.files[0];
                      setFieldValue(FORM_FIELDS.IMAGE3, file);
                      setImage3(file ? URL.createObjectURL(file) : null);
                    }}
                  />
                  {image3 && (
                    <img
                      className="admin-form-image"
                      src={image3}
                      alt="Preview 3"
                    />
                  )}
                </div>
              </div>

              <div className="admin-form-group">
                <Field name={FORM_FIELDS.NAME}>
                  {({ field, form, meta }) => (
                    <InputFieldFormik
                      field={field}
                      form={form}
                      meta={meta}
                      label="Product Name"
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
                <Field name={FORM_FIELDS.HEEL_HEIGHT}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Heel Height"
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
              </div>
              <div className="admin-form-group">
                <Field name={FORM_FIELDS.PRICE}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Price"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {discountAmount > 0 && price > 0 && (
                              <span
                                style={{ marginRight: "10px", color: "red" }}
                              >
                                ({formattedDiscountedPrice} ) RSD
                              </span>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
                <Field name={FORM_FIELDS.DISCOUNT_AMOUNT}>
                  {({ form, ...formik }) => (
                    <InputFieldFormik
                      form={form}
                      {...formik}
                      label="Discount Amount (%)"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      fullWidth
                      size="small"
                    />
                  )}
                </Field>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <Field name={FORM_FIELDS.TAGS}>
                  {({ form, ...formik }) => (
                    <>
                      <InputFieldFormik
                        form={form}
                        {...formik}
                        label="Tags (comma-separated)"
                        value={displayedTags.join(", ")}
                        onChange={(e) =>
                          setFieldValue(FORM_FIELDS.TAGS, e.target.value)
                        }
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowTagDropdown((prev) => !prev)
                                }
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {showTagDropdown && (
                        <CustomDropdown
                          color={true}
                          options={tagOptions.map((option) => ({
                            ...option,
                            disabled:
                              option.value === "sale" &&
                              values.discountAmount === 0,
                          }))}
                          selectedValues={displayedTags}
                          onChange={(selected) => {
                            const cleanedTags = selected
                              .map((tag) => tag.trim())
                              .filter((tag) => tag !== "");

                            if (
                              values.discountAmount > 0 &&
                              !displayedTags.includes("sale")
                            ) {
                              displayedTags.push("sale");
                            }
                            if (!cleanedTags.includes("sale")) {
                              setFieldValue(FORM_FIELDS.DISCOUNT_AMOUNT, 0);
                            }
                            setFieldValue(
                              FORM_FIELDS.TAGS,
                              cleanedTags.join(", ")
                            );
                            setShowTagDropdown(false);
                          }}
                        />
                      )}
                    </>
                  )}
                </Field>
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <Field name={FORM_FIELDS.COLORS}>
                  {({ form, ...formik }) => (
                    <>
                      <InputFieldFormik
                        form={form}
                        {...formik}
                        label="Colors (comma-separated)"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setShowColorDropdown((prev) => !prev)
                                }
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {showColorDropdown && (
                        <CustomDropdown
                          options={colorOptions}
                          selectedValues={values[FORM_FIELDS.COLORS]
                            .split(",")
                            .map((color) => color.trim())
                            .filter((color) => color !== "")}
                          onChange={(selected) => {
                            const cleanedColors = selected
                              .map((color) => color.trim())
                              .filter((color) => color !== "");
                            setFieldValue(
                              FORM_FIELDS.COLORS,
                              cleanedColors.join(", ")
                            );
                            setShowColorDropdown(false);
                          }}
                        />
                      )}
                    </>
                  )}
                </Field>
              </div>
              <Field name={FORM_FIELDS.SIZE}>
                {({ form, ...formik }) => (
                  <InputFieldFormik
                    form={form}
                    {...formik}
                    label="Size (comma-separated)"
                    fullWidth
                    size="small"
                  />
                )}
              </Field>

              <Field name={FORM_FIELDS.OUT_OF_STOCK_SIZES}>
                {({ form, ...formik }) => (
                  <InputFieldFormik
                    form={form}
                    {...formik}
                    label="Out of Stock Sizes (comma-separated)"
                    fullWidth
                    size="small"
                  />
                )}
              </Field>

              <CustomButton
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={!isValid}
                className="submit-btn"
                textButton="ADD PRODUCT"
                backgroundColor="#007BFF"
                hoveBgColor="#0056b3"
                isAdmin={true}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AdminPanelAddProduct;
