import { createSlice } from "@reduxjs/toolkit";

export const THEME_MODES = {
  LIGHT: "light",
  DARK: "dark",
};

export const LANGUAGE_OPTIONS = {
  ENGLISH: "en",
  TURKISH: "tr",
  SERBIAN: "sr",
};

const getDefaultLanguage = () => {
  const browserLanguage = navigator.language.split("-")[0]; 

  if (browserLanguage === "sr" || browserLanguage === "sh") {
    return LANGUAGE_OPTIONS.SERBIAN; 
  }

  return LANGUAGE_OPTIONS.ENGLISH;  
};
console.log("Browser language:", navigator.language);

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAdmin: false,  
    isLoggedIn: !!localStorage.getItem("token"),
    token: localStorage.getItem("token"), 
    language: getDefaultLanguage(), 
    theme: THEME_MODES.LIGHT,  
    screenSize: "large",  

  },
  reducers: {
    setThemeMode(state) {
      state.theme = state.theme === THEME_MODES.LIGHT ? THEME_MODES.DARK : THEME_MODES.LIGHT;
    },
    setLanguage(state, action) {
      const { language } = action.payload;
      if (Object.values(LANGUAGE_OPTIONS).includes(language)) {
        state.language = language;
      }
    },
    setScreenSize(state, action) {
      state.screenSize = action.payload; 
    },
    setAdminStatus(state, action) {
      const { isAdmin, token } = action.payload; 
      state.isAdmin = isAdmin;
      state.isLoggedIn = true;
      state.token = token;

      localStorage.setItem("token", token);
    },
    clearAdminStatus(state) {
      state.isAdmin = false;
      state.isLoggedIn = false; 
      state.token = null;

      localStorage.removeItem("token");
    },
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const { 
  setAdminStatus, 
  clearAdminStatus, 
  setThemeMode, 
  setLanguage, 
  setScreenSize
} = userSlice.actions;

export const selectIsAdmin = (state) => state.user.isAdmin;
export const selectActiveTheme = (state) => state.user.theme;
export const selectActiveLanguage = (state) => state.user.language;
export const selectScreenSize = (state) => state.user.screenSize;
export const selectToken = (state) => state.user.token; 

export default userSlice.reducer;
