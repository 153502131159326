import "./cart-modal.css";
import { isMobile } from "react-device-detect";
import { Divider, Tooltip, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  addItemToCart,
  removeItemsFromCart,
  selectItems,
  selectTotalCartPrice,
} from "../../../store/reducers/cartSlice";
import { displayNotification } from "../../../store/reducers/notificationSlice";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../../store/reducers/userSlice";
import { translations } from "../../../helpers/translations";
import { NOTIFICATION_TYPES } from "../../../helpers/app.constants";
import { useNavigate } from "react-router-dom";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import CustomButton from "../../other/CustomButton";

function CartModal({ onClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const isMedium = useMediaQuery("(max-width: 1000px)");
  const isMediumBigger = useMediaQuery("(max-width: 1500px)");
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const modalClassName = isSmallScreen
    ? "cart-modal-container"
    : "cart-modal-container animate-slide-in";

  const items = useSelector(selectItems);
  const totalPrice = useSelector(selectTotalCartPrice);
  const cartEmpty = items.length === 0;

  const addToCartHandler = (item) => {
    if (!item.product || !item.size) {
      console.error("Invalid item data:", item);
      return;
    }
    dispatch(addItemToCart({ product: item.product, size: item.size }));
    dispatch(
      displayNotification({
        text: `${item.product.name} ${translatedLanguages.addedToCart}!`,
        type: NOTIFICATION_TYPES.SUCCESS,
      })
    );
  };

  const removeFromCartHandler = (item) => {
    if (!item.product || !item.size) {
      console.error("Invalid item data:", item);
      return;
    }
    dispatch(removeItemsFromCart({ id: item.product._id, size: item.size }));
    dispatch(
      displayNotification({
        text: `${item.product.name} ${translatedLanguages.removedFromCart}!`,
        type: NOTIFICATION_TYPES.ERROR,
      })
    );
  };
  const iconStyles = {
    color: theme.palette.customOpposite.main,
    stroke: "white",
    strokeWidth: 1,
  };
  return (
    <div
      className={modalClassName}
      style={{
        top: isSmallScreen ? "85px" : "85px",
        right: isSmallScreen ? "0" : "0",
        width: isSmallScreen
          ? "100%"
          : isMedium
          ? "35vw"
          : isMediumBigger
          ? "27.5vw"
          : "20vw",
        minHeight: isSmallScreen ? "90vh" : "calc(100vh - 85px)",
        height: "fit-content",
        backgroundColor: theme.palette.primary.main1,
        border: `1px solid ${theme.palette.customOppositeLighter.main}`,
        borderRight: "none",
        fontSize: isSmallScreen ? "1em" : "0.95em",
        justifyContent: cartEmpty ? "center" : "space-between",
        alignItems: cartEmpty ? "center" : "flex-start",
        zIndex: 40,
      }}
    >
      <div
        className="cart-modal-content"
        style={{
          width: isSmallScreen ? " 100%" : "100%",
          paddingBottom: isSmallScreen ? "7vh 0" : "2vh 0",
        }}
      >
        <div className="cart-close-icon-container">
          <CloseIcon
            onClick={onClose}
            className="cart-close-icon"
            sx={{
              ...iconStyles,
              fontSize: isSmallScreen ? "1.6em !important" : "1.7em !important",
            }}
          />
        </div>
        {cartEmpty ? (
          <div
            className="cart-step-empty-inner"
            style={{ height: " 75vh", width: "100%" }}
          >
            <ShoppingBagOutlinedIcon
              sx={{
                fontSize: isSmallScreen ? "3rem" : "5rem",
                stroke: "white",
                strokeWidth: 1,
              }}
            />
            <p style={{ marginTop: "2vh", fontWeight: 300 }}>
              {translatedLanguages.cartEmpty}
            </p>

            <CustomButton
              textButton={
                translatedLanguages.continueShopping || "Continue Shopping"
              }
              onClick={() => navigate("/shop")}
              width="70%"
              display="flex"
              justifyContent="center"
            />
          </div>
        ) : (
          <>
            {items.map((item) => {
              return (
                <div
                  className="cart-item-container"
                  key={`${item.product._id}-${item.size}`}
                >
                  <div className="cart-item">
                    <img
                      src={item.product.image}
                      alt={item.product.name}
                      className="cart-item-image"
                    />
                    <div className="cart-item-info">
                      <div className="cart-item-title">{item.product.name}</div>
                      <span className="cart-item-count">
                        {translatedLanguages.size}:&nbsp;{item.size}
                      </span>
                    </div>
                    <span className="cart-item-count">x {item.count}</span>
                    <div className="cart-item-actions">
                      <Tooltip title={translatedLanguages.remove}>
                        <RemoveOutlinedIcon
                          onClick={() => removeFromCartHandler(item)}
                          className="cart-item-action-icon"
                          sx={iconStyles}
                        />
                      </Tooltip>
                      <Tooltip title={translatedLanguages.add}>
                        <AddOutlinedIcon
                          onClick={() => addToCartHandler(item)}
                          className="cart-item-action-icon"
                          sx={iconStyles}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <Divider
                    sx={{
                      width: "88%",
                      backgroundColor: theme.palette.customOppositeLighter.main,
                      margin: "auto",
                    }}
                    orientation="horizontal"
                    variant="middle"
                    flexItem
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      {!cartEmpty && (
        <div
          style={{
            color: theme.palette.customOpposite.main,
            backgroundColor: theme.palette.primary.main1,
            border: `1px solid ${theme.palette.customOppositeLighter.main}`,
            borderBottom: "none",
            marginBottom: isSmallScreen && "10vh",
          }}
          className="cart-total-price"
        >
          <div className="cart-procced">
            <CustomButton
              textButton={translatedLanguages.cartReview || "Cart"}
              onClick={() => navigate("/cart-checkout")}
              width="100%"
              backgroundColor="#97E78A"
              hoveBgColor="#65E05E"
            />
          </div>
          <Divider
            sx={{
              width: "100%",
              backgroundColor: theme.palette.customOppositeLighter.main,
              margin: "auto",
            }}
            orientation="horizontal"
            variant="middle"
            flexItem
          />
          <div
            className="cart-price "
            style={{
              fontSize: isSmallScreen ? "1.2em" : isMedium ? "1.1em" : "1.3em ",
            }}
          >
            <div
              style={{
                color: "#00AC11",
              }}
            >
              {totalPrice.toLocaleString("de-DE")},00 RSD
            </div>
            &nbsp;
            {translatedLanguages.total}
          </div>
        </div>
      )}
    </div>
  );
}

export default CartModal;
