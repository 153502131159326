import { isMobile } from "react-device-detect";
import "./cart-checkout.css";
import {
	selectActiveLanguage,
	selectScreenSize,
} from "../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import Bredcrumbs from "../other/Bredcrumbs";
import { translations } from "../../helpers/translations.js";
import { useEffect, useState } from "react";
import CartStep from "./cartSteps/cartStep.js";
import CheckoutStep from "./cartSteps/CheckoutStep";
import ConfirmationStep from "./cartSteps/ConfirmationStep";


function CartCheckout() {
	const screenSize = useSelector(selectScreenSize);
	const isSmallScreen = screenSize === "small" || isMobile;
	const currentLanguage = useSelector(selectActiveLanguage);
	const translatedLanguages = translations[currentLanguage] || {};
	const [currentStep, setCurrentStep] = useState(1);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentStep]);

	const handleStepClick = (step) => {
		if (currentStep === 3) {
			return;
		}
	
		if (step <= currentStep) {
			setCurrentStep(step);
		}
	};

	return (
		<div
			className="cart-checkout-page-container"
			style={{
				minHeight: isSmallScreen ? "95vh" : "100vh",
				height: "fit-content",
				width: isSmallScreen ? "100%" : "calc(100% - 60px)",
				marginLeft: isSmallScreen ? "" : "50px",
			}}
		>
			<Bredcrumbs
				pageName={translatedLanguages.reviewCartLink}
				pageLink="/cart-checkout"
			/>
			<div
				className="progress-container"
				style={{
					marginTop: isSmallScreen ? "10vh" : "5vh",
					width: isSmallScreen ? "75%" : "50%",
				}}
			>
				<div className="circle-wrapper">
					<div className="circle-label">{translatedLanguages.cart}</div>
					<div
						className={`circle ${currentStep >= 1 ? "active" : ""}`}
						onClick={() => handleStepClick(1)}
					>
						1
					</div>
				</div>

				<div className="progress-line">
					<div
						className="filled"
						style={{ width: `${currentStep >= 2 ? "100%" : "0%"}` }}
					></div>
				</div>

				<div className="circle-wrapper">
					<div className="circle-label">{translatedLanguages.checkout}</div>
					<div
						className={`circle ${currentStep >= 2 ? "active" : ""}`}
						onClick={() => handleStepClick(2)}
					>
						2
					</div>
				</div>

				<div className="progress-line">
					<div
						className="filled"
						style={{ width: `${currentStep === 3 ? "100%" : "0%"}` }}
					></div>
				</div>

				<div className="circle-wrapper">
					<div className="circle-label">{translatedLanguages.confirmation}</div>
					<div
						className={`circle ${currentStep === 3 ? "active" : ""}`}
						onClick={() => handleStepClick(3)}
					>
						3
					</div>
				</div>
			</div>

			<div
				className="step-content"
				style={{
					margin: isSmallScreen ? "7vh 0 0 0" : "5vh 0",
					width: isSmallScreen ? "93%" : "50%",
					minHeight: isSmallScreen ? "65vh" : "80vh",
					boxShadow: isSmallScreen
					? "0 0 15px rgba(0, 0, 0, 0.3)"
					: "0 0px 8px rgba(0, 0, 0, 0.3)",
				}}
			>
				{currentStep === 1 && <CartStep setCurrentStep={setCurrentStep} />}
				{currentStep === 2 && <CheckoutStep setCurrentStep={setCurrentStep} />}
				{currentStep === 3 && <ConfirmationStep />}
			</div>
		</div>
	);
}

export default CartCheckout;
