import React from "react";
import ProductContainer from "../../other/ProductContainer";
import "./section-component.css";
import { Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { setNoProductsFound } from "../../../store/reducers/filterSlice";
import { handleFilterSubmit } from "../../../helpers/filterUtilsSubmit";

const SectionComponent = ({
  title,
  filteredProducts,
  isSmallScreen,
  isMedium,
  navigate,
  translatedLanguages,
  similarProducts,
  filterTag,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch(); // Use dispatch to trigger Redux actions

  const handleTitleClick = () => {
    const filters = { filters: [filterTag] }; // Prepare the filters payload with the selected tag

    // Call handleFilterSubmit to apply the filter and fetch products
    handleFilterSubmit(
      filters, // Pass the filters
      dispatch, // Redux dispatch
      [], // Heel heights (empty if not needed)
      [], // Sizes (empty if not needed)
      [filterTag], // Dynamic tags (using the clicked tag)
      [], // Dynamic colors (empty if not needed)
      isSmallScreen, // isSmallScreen flag
      navigate, // navigate function to move to the shop page
      null // No need to setFiltersExpanded here
    );

    // Navigate to the shop page with the filter tag in the URL
    navigate(`/shop?filter=${filterTag}`, { state: { filterExpanded: false } });
  };

  if (!filteredProducts || filteredProducts.length === 0) {
    return null;
  }

  return (
    <div className="section-container">
      <h1
        className="section-title"
        style={{
          marginLeft: isSmallScreen ? "" : "2vh",
          justifyContent: isSmallScreen ? "center" : "flex-start",
        }}
        onClick={handleTitleClick}
      >
        {title}
      </h1>
      <Divider
        sx={{
          width: isSmallScreen ? "93%" : "98%",
          backgroundColor: theme.palette.customOppositeLighter.main,
        }}
        orientation="horizontal"
        variant="middle"
        flexItem
      />
      <div
        className="products-grid"
        style={{
          width: isSmallScreen ? "100%" : "calc(100% - 50px)",
          display: "grid",
          justifyItems: "center",
          alignItems: "center",
          justifyContent: "center",
		  width:"100%"
        }}
      >
        {filteredProducts.map((product) => (
          <ProductContainer
            className="product-container"
            key={product._id}
            product={product}
          />
        ))}
      </div>
      {similarProducts && (
        <div
          className="products-allproducts-link"
          style={{
            bottom: isSmallScreen ? "4vh" : isMedium ? "0vh" : "3vh",
          }}
          onClick={() => navigate("/shop")}
        >
          {translatedLanguages.viewAllProducts} <span>&gt;</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(SectionComponent);
