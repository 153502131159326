import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  clearAdminStatus,
  selectIsAdmin,
} from "../../store/reducers/userSlice";
import AdminPanelOrderDetails from "../components/adminPanelOrderDetails/AdminPanelOrderDetails";
import AdminPanelEditProduct from "./AdminPanelEditProduct";
import { selectNewOrdersCount } from "../../store/reducers/adminSlice";
import useOrdersCount from "../components/hooks/useOrdersCount";
import { ADMIN_PANEL_TABS } from "../components/adminTabs";
import { resetOrdersCount } from "../components/adminUtils";
import { AdminPanelTabDrawer } from "../components/AdminPanelTabDrawer";

const AdminPanel = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const isAdminLoggedIn = useSelector(selectIsAdmin);
  const newOrdersCount = useSelector(selectNewOrdersCount);

  const [activeTab, setActiveTab] = useState(
    ADMIN_PANEL_TABS.find((tab) => tab.key === "ALL_PRODUCTS") ||
      ADMIN_PANEL_TABS[0]
  );

  useOrdersCount();

  const tabs = useMemo(() => {
    const baseTabs = [...ADMIN_PANEL_TABS];

    if (location.pathname.includes("/admin/order-details")) {
      baseTabs.push({
        key: "ORDER_DETAILS",
        label: `ORDER DETAILS`,
        component: <AdminPanelOrderDetails setActiveTab={setActiveTab} />,
        path: `order-details/${id}`,
      });
    }
    if (location.pathname.includes("/admin/product-edit")) {
      baseTabs.push({
        key: "EDIT_PRODUCT",
        label: `EDIT PRODUCT`,
        component: <AdminPanelEditProduct setActiveTab={setActiveTab} />,
        path: `product-edit/${id}`,
      });
    }

    return baseTabs;
  }, [location.pathname, id]);

  const handleLogout = () => {
    dispatch(clearAdminStatus());
    navigate("/admin");
  };

  useEffect(() => {
    if (!isAdminLoggedIn) {
      navigate("/admin");
    } else {
      let currentTab = tabs.find((tab) => location.pathname.includes(tab.path)) ||
        tabs.find((tab) => tab.key === "ALL_PRODUCTS");
  
      if (location.pathname.includes("order-details")) {
        currentTab = tabs.find((tab) => tab.key === "ORDERS");
      }
  
      if (currentTab) {
        setActiveTab(currentTab);
      }
    }
  }, [isAdminLoggedIn, location.pathname, tabs, navigate]);
  

  const handleSettingActiveTab = useCallback(
    (tabKey) => {
      const selectedTab = tabs.find((tab) => tab.key === tabKey);
      setActiveTab(selectedTab);

      if (selectedTab?.path) {
        navigate(`/admin/admin-panel/${selectedTab.path}`);
      } else {
        navigate("/admin/admin-panel"); 
      }
    },
    [tabs, navigate]
  );

  if (!isAdminLoggedIn) return null;

  return (
    <Box sx={{ display: "flex" }}>
      <AdminPanelTabDrawer
        tabs={tabs}
        activeTab={activeTab}
        handleSettingActiveTab={handleSettingActiveTab}
        newOrdersCount={newOrdersCount}
        handleResetOrdersCount={() => resetOrdersCount(dispatch)}
        handleLogout={handleLogout}
        theme={theme}
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: theme.palette.background.default }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.background.paper,
            width: "100%",
            minHeight: "100vh",
          }}
        >
          {!location.pathname.includes("product-edit") &&
          !location.pathname.includes("order-details") ? (
            activeTab.component
          ) : (
            <Outlet />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminPanel;
