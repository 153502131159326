import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  setDataState,
  displayNotification,
} from "../../store/reducers/notificationSlice";
import { DATA_STATE, NOTIFICATION_TYPES, SERVER_URL } from "../app.constants";
import socket from "../socket";

const useGetProductById = (productId) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null); 
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
      try {
        const response = await axios.get(`${SERVER_URL}product/getproduct/${productId}`);
        setProduct(response.data.product); 
      } catch (error) {
        setError("Server error!");
        const notificationPayload = {
          text: "Server error!",
          type: NOTIFICATION_TYPES.ERROR,
        };
        dispatch(displayNotification(notificationPayload)); 
      } finally {
        dispatch(setDataState(DATA_STATE.DATA_STATE_OK)); 
      }
    };

    if (productId) {
      fetchProduct(); 
    }
  }, [productId, dispatch]);

  useEffect(() => {
    socket.on("productUpdated", (data) => {
      if (data.product._id === productId) {
        setProduct(data.product); 
      }
    });

    socket.on("productDeleted", ({ productId: deletedProductId }) => {
      if (deletedProductId === productId) {
        setProduct(null); 
        setError("Product was deleted");
      }
    });

    return () => {
      socket.off("productUpdated");
      socket.off("productDeleted");
    };
  }, [productId]);

  return { product, error }; 
};

export default useGetProductById;
