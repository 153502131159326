import "./breadcrumbs.css";
import { useTheme } from "@emotion/react";
import TuneIcon from "@mui/icons-material/Tune";
import { Breadcrumbs } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  selectActiveLanguage,
  selectScreenSize,
} from "../../store/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsFilterExpanded,
  setFilterExpanded,
} from "../../store/reducers/filterSlice.js";
import { translations } from "../../helpers/translations.js";

function Bredcrumbs({ pageName, pageLink, pageName2, pageLink2, isShopPage }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const currentLanguage = useSelector(selectActiveLanguage);
  const translatedLanguages = translations[currentLanguage] || {};
  const isExpanded = useSelector(selectIsFilterExpanded);
 

  const handleClickExpanded = () => {
    dispatch(setFilterExpanded(!isExpanded));
  };

  const iconStyles = (isExpanded) => ({
    fontSize: "1.75em",
    color: theme.palette.customOpposite.main,
    transition: "transform 0.3s ease-in-out",
    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
    cursor: "pointer",
    stroke: "white",
    strokeWidth: 0.5,
  });


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "96.5%",
        marginTop: isSmallScreen ? "140px" : "97px",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        className="breadcrumbs"
        sx={{
          marginLeft: isSmallScreen ? "2vh" : "2vh",
        }}
      >
        <p className="breadcrumb" onClick={() => navigate("/")}>
          {translatedLanguages.home}
        </p>

        <p className="breadcrumb" onClick={() => navigate(pageLink)}>
          {pageName}
        </p>
        {pageName2 && (
          <p className="breadcrumb" onClick={() => navigate(pageLink2)}>
            {pageName2}
          </p>
        )}
      </Breadcrumbs>
      <div className="bredcrumbs-filter-icon-container">
      </div>
    </div>
  );
}

export default Bredcrumbs;
