import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import FilterComponent from "../components/filter/FilterComponent";
import { Outlet } from "react-router-dom";

const MainLayout = ({ isSmallScreen }) => {
  return (
    <>
      <Header />
      {!isSmallScreen && <FilterComponent />}
      <Outlet />
      <Footer />
    </>
  );
};

export default MainLayout;
