import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { applyDiscount } from "./utils";

const ProductList = ({ products, fetchedProducts, isSmallScreen }) => (
  <Box sx={{ padding: 2 }}>
    {products.map((product, index) => {
      const fullProduct = fetchedProducts.find(
        (p) => p._id === product.productID
      );

      return (
        <Paper elevation={3} key={index} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="h6" gutterBottom>
            Product {index + 1}
          </Typography>
          {fullProduct ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <img
                  src={fullProduct.image || "/placeholder-image.jpg"}
                  alt={fullProduct.name}
                  style={{ width: isSmallScreen ? "20%" : "100%", height: "auto", borderRadius: "8px" }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Typography variant="body1">
                  <span>Name: </span>
                  <strong>{fullProduct.name}</strong>
                </Typography>
                <Typography variant="body2" sx={{ display: "flex" }}>
                  <span style={{ width: "50px" }}>Size:</span>
                  <strong>{product.size}</strong>
                </Typography>
                <Typography variant="body2" sx={{ display: "flex" }}>
                  <span style={{ width: "50px" }}>Count:</span>
                  <strong>x {product.count}</strong>
                </Typography>
                <Typography variant="body2">
                  <span> Original price: </span>
                  {fullProduct.price.toLocaleString("de-DE")},00 RSD
                </Typography>

                {fullProduct.discountAmount > 0 ? (
                  <>
                    <Typography variant="body2" sx={{ color: "red" }}>
                      <span> Discount amount:</span> -{" "}
                      {applyDiscount(
                        fullProduct.price,
                        fullProduct.discountAmount
                      )[1].toLocaleString("de-DE")}{" "}
                      RSD
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      <span> Final price after discount:</span>
                      {applyDiscount(
                        fullProduct.price,
                        fullProduct.discountAmount
                      )[0].toLocaleString("de-DE")}
                      ,00 RSD
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body1" sx={{ color: "red" }}>
                    No Discount
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography
              variant="body1"
              color="error"
              sx={{ fontWeight: "bold" }}
            >
              Product not found
            </Typography>
          )}
        </Paper>
      );
    })}
  </Box>
);

export default ProductList;
