import AdminPanelAddProduct from "../pages/AdminPanelAddProduct";
import AdminPanelGallery from "../pages/AdminPanelGallery";
import AdminPanelOrders from "../pages/AdminPanelOrders";
import AdminPanelProducts from "../pages/AdminPanelProducts";

export const ADMIN_PANEL_TABS = [
  {
    key: "ORDERS",
    label: "ORDERS",
    component: <AdminPanelOrders />,
    path: "orders",
  },
  {
    key: "ALL_PRODUCTS",
    label: "ALL PRODUCTS",
    component: <AdminPanelProducts />,
    path: "products",
  },
  {
    key: "ADD_PRODUCT",
    label: "ADD PRODUCTS",
    component: <AdminPanelAddProduct />,
    path: "add-product",
  },
  {
    key: "IMAGE_GALLERY",
    label: "OTHER",
    component: <AdminPanelGallery />,
    path: "gallery",
  },
];
