import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getColors } from "../../adminPanel/adminPanel.actions";

const useGetColors = () => {
  const dispatch = useDispatch();
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const fetchColors = async () => {
      const fetchedColors = await dispatch(getColors());
      //console.log("Fetched Colors:", fetchedColors); 
      setColors(fetchedColors);
    };

    fetchColors();
  }, [dispatch]);

  return colors;
};

export default useGetColors;
